export const getPurchase = async (
  {
    // param,setNumberOfRecords,setPurchaseItems,getPurchaseDetail,setTotalAmount
    param,
     setNumberOfRecords,
    setPurchaseItems,
     getPurchaseDetail, 
      setTotalAmount
  }
  ) => {
    getPurchaseDetail(param)
      .then((res) => {
        console.log("purchase ", res);

        setNumberOfRecords(res?.data?.data?.totalRecords);
        setPurchaseItems(res?.data?.data?.purchaseBills);
        setTotalAmount(res?.data?.data?.totalAmount)
        
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  