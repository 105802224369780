import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Pagination,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import { addUserFaq } from "./helpAndSupportApi";
import { payloadDateFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { addDataBillerService } from "./helpAndSupportCustomApiHandlers";
import Swal from "sweetalert2";
import { checkValidationHelpSupportModal } from "./helpAndSupportValidationFunctions";

export default function HelpModal({ open, setOpen, item, selectedFilterObj }) {
  const data = {
    id: 0,
    date: payloadDateFormat(),
    purpose: "",
    category: selectedFilterObj?.category,
    description: "",
    loggedBy: "",
    interimUpdate: "",
    updateDate: payloadDateFormat(),
    closureDescription: "",
    closureDate: payloadDateFormat(),
    daysElapsed: 0,
    interimStatus: "",
    closureStatus: "",
  };
  const [modalData, setModalData] = useState({
    id: 0,
    date: payloadDateFormat(),
    purpose: "",
    category: selectedFilterObj?.category,
    description: "",
    loggedBy: "",
    interimUpdate: "",
    updateDate: payloadDateFormat(),
    closureDescription: "",
    closureDate: payloadDateFormat(),
    daysElapsed: 0,
    interimStatus: "",
    closureStatus: "",
  });
  const [radioValue, setRadioValue] = useState("query");
  const [textAreaValue, setTextAreaValue] = useState("");
  useEffect(() => {
    if (open === true) {
      setModalData({
        ...modalData,
        category: selectedFilterObj?.category,
        purpose: "Query",
      });
    } else {
      // setModalData();
    }
  }, [open]);
  useEffect(() => {
    console.log("item modal", selectedFilterObj?.category);
    console.log("item modal =", modalData);
  }, [modalData]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "50%",
          overflowY: "auto",
          height: "auto",
          position: "absolute",
          left: "30%",
          top: "5%",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Write to us{" "}
          <CloseIcon sx={{ float: "right" }} onClick={() => setOpen(false)} />
        </DialogTitle>
        <Divider />
        <Grid container>
          <Grid item xs={12}>
            <RadioGroup
              row
              sx={{
                fontWeight: "500",
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
              }}
              value={modalData?.purpose}
              // value={billFilter}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(e) => {
                // setRadioValue(e.target.value);
                setModalData({ ...modalData, purpose: e.target.value });
              }}
            >
              <FormControlLabel
                selected
                value="Query"
                control={<Radio color="secondary" />}
                label="Query "
              />
              <FormControlLabel
                value="Complain"
                control={<Radio color="secondary" />}
                label="Complain"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sx={{ p: "10px" }}>
            <TextField
              // sx={{ border: "none" }}
              label="Write to us"
              multiline
              rows={4}
              value={textAreaValue}
              variant="outlined"
              fullWidth
              sx={{
                borderRadius: "0px", // Optionally remove border radius for a completely straight bottom border
                "& .MuiOutlinedInput-root": {
                  border: "none",
                  "&.Mui-focused": {
                    borderBottom: "1px solid #000", // Customize the color and style of the bottom border when focused
                  },
                  "&:hover": {
                    borderBottom: "1px solid #000", // Customize the color and style of the bottom border on hover
                  },
                },
              }}
              onChange={(e) => {
                setTextAreaValue(e.target.value);

                setModalData({ ...modalData, description: e.target.value });
              }}
              // Add any other props you need, like onChange, value, etc.
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              let validation = checkValidationHelpSupportModal(modalData);
              if (validation?.status) {
                addDataBillerService(modalData, addUserFaq, Swal);
              } else {
                Swal.fire({
                  text: validation?.message,
                  icon: "warning",
                  ...swalAlertPopProps,
                });
              }
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}
