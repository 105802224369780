import { routePath } from "../constants/routePath";
import ItemCategories from "../features/ItemsCategories/itemCategories";
import AssociatedUsers from "../features/associatedUsers/associatedUsers";
import CounterSell from "../features/counterSell/counterSell";
import CounterSellBilling from "../features/counterSellBilling/counterSellBilling";
import Dashboard from "../features/dashboard/dashboard";
import Expense from "../features/expense/expense";
import HelpAndSupport from "../features/helpAndSupport/helpAndSupport";
import ItemServices from "../features/items_services/ItemServices";
import KycProcess from "../features/kycProcess/kycProcess";
import CounterToken from "../features/landingPage/counterToken";
import Franchise from "../features/landingPage/franchise";
import LandingPage from "../features/landingPage/landingPage";
import MonthlyBill from "../features/landingPage/monthlyBill";
import { RestaurantPage } from "../features/landingPage/restaurantPage";
import Login from "../features/login/login";
import MobileMenuPage from "../features/mobileMenu/mobileMenuPage";
import MyBusiness from "../features/myBusiness/myBussiness";
import MyProfile from "../features/myProfile/myProfile";
import OutstandingReport from "../features/outStandingReport/outStandingReport";
import PaymentReceipt from "../features/paymentReceipt/paymentReceipt";
import Purchase from "../features/purchase/purchase";
import CashCounterSale from "../features/reports/cashCounterSale/cashCounterSale";
import RateChangeItemHistory from "../features/reports/rateChangeHistory/rateChangeHistory";
import Reports from "../features/reports/reports";
import ResetMpin from "../features/resetPin/resetPin";
import Setting from "../features/setting/setting";
import SmbAccountReacharge from "../features/smbAccoundReacharge/smbAccoundReacharge";
import Stocks from "../features/stocks/stocks";
import TableMaster from "../features/tableMaster/tableMaster";
import TableSection from "../features/tableSection/tableSection";
import UpdateBills from "../features/updateBills/updateBills";
const menuLoginHeader = [
  {
    title: "Home",
  },
  {
    title: "About",
  },
];
export const RoutesSet = [
  {
    routePath: routePath.LANDINGPAGE,
    component: <LandingPage />,
  },
  {
    routePath: routePath.LANDINGPAGERESTORENT,
    component: <RestaurantPage />,
  },
  {
    routePath: routePath.LANDINGPAGECOUNTERTOKEN,
    component: <CounterToken />,
  },
  {
    routePath: routePath.LANDINGPAGEMONTHLYBILL,
    component: <MonthlyBill />,
  },
  {
    routePath: routePath.LANDINGPAGEFRANCHISE,
    component: <Franchise />,
  },
  {
    routePath: routePath.LOGIN,
    component: <Login />,
  },
  {
    routePath: routePath.DASHBOARD,
    component: <Dashboard />,
  },
  {
    routePath: routePath.COUNTERSELL,
    component: <CounterSell />,
  },
  {
    routePath: routePath.COUNTERSELLBILLING,
    component: <CounterSellBilling />,
  },
  {
    routePath: routePath.UPDATEBILLS,
    component: <UpdateBills />,
  },
  {
    routePath: routePath.MYPROFILE,
    component: <MyProfile />,
  },
  {
    routePath: routePath.MYBUSSINESS,
    component: <MyBusiness />,
  },
  {
    routePath: routePath.TABLEMASTER,
    component: <TableMaster />,
  },
  {
    routePath: routePath.ITEMSERVICES,
    component: <ItemServices />,
  },
  {
    routePath: routePath.TABLESECTION,
    component: <TableSection />,
  },
  {
    routePath: routePath.ITEMCATEGORIES,
    component: <ItemCategories />,
  },
  { routePath: routePath.ASSOCIATEDUSERS, component: <AssociatedUsers /> },
  {
    routePath: routePath.PAYMENTRECEIPT,
    component: <PaymentReceipt />,
  },
  {
    routePath: routePath.OUTSTANDINGREPORT,
    component: <OutstandingReport />,
  },
  {
    routePath: routePath.MOBILEPAGEMENU,
    component: <MobileMenuPage menuLoginHeader={menuLoginHeader} />,
  },
  {
    routePath: routePath.SMBACCOUNTREACHARGE,
    component: <SmbAccountReacharge />,
  },
  {
    routePath: routePath.REPORTS,
    component: <Reports />,
  },
  {
    routePath: routePath.STOCKS,
    component: <Stocks />,
  },
  {
    routePath: routePath.PURCHASE,
    component: <Purchase />,
  },
  {
    routePath: routePath.EXPENCE,
    component: <Expense />,
  },
  {
    routePath: routePath.HELPSUPPORT,
    component: <HelpAndSupport />,
  },
  {
    routePath: routePath.RESETMPIN,
    component: <ResetMpin />,
  },
  {
    routePath: routePath.SETTING,
    component: <Setting />,
  },
  {
    routePath: routePath.RATECHANGE,
    component: <RateChangeItemHistory />,
  },
  {
    routePath: routePath.CASHCOUNTERSALE,
    component: <CashCounterSale />,
  },
  {
    routePath: routePath.KYCPROCESS,
    component: <KycProcess />,
  },
];
