import {
  GETCOUNTERSALESREPORT,
  GETMONTLYGSTDETAILS,
  GETOVERALLCOUNTERDETAILS,
  GETRATECHANGEHISTORY,
} from "../../../utils/api";
import axios from "../../../utils/axios";

// export const getCategorySalesCustomers=async(param)=>{
//     const res=await axios.post(GETCATEGORYWISECOUNTERSALE,param);
//     return res;
// }
export const getMonthlyDetails = async (param) => {
  const res = await axios.post(GETMONTLYGSTDETAILS, param);
  return res;
};

export const getOverallCounterDetail = async (param) => {
  const res = await axios.post(GETOVERALLCOUNTERDETAILS, param);
  return res;
};
export const getCounterSalesReport = async (param) => {
  const res = await axios.post(GETCOUNTERSALESREPORT, param);
  return res;
};

export const getRateChangeHistory = async (param) => {
  // const re
  const res = await axios.post(GETRATECHANGEHISTORY, param);
  return res;
};
