import { AD1DCASHPAYMENT, ADDCASHPAYMENT, GETCOUNTERSALESCUSTOMERS, GETCOUNTERSELAESSTATEMENT, GETPAYMENTRECEIPT, GETPAYMENTRECEIPTS, GETPAYMENTXLSX, GETUNPAIDBILLS } from "../../utils/api"
import axios from "../../utils/axios";

export const getCounterSalesStatement=async(param)=>{
    const res=await axios.post(GETCOUNTERSELAESSTATEMENT,param)
    return res;
}







 