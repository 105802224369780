import { createSlice } from "@reduxjs/toolkit";

import { routePath } from "../../constants/routePath";

const initialState = {
  loading: false,
  snackbar: {
    open: false,
    message: "",
    severity: "success",
    duration: 2000,
  },
  drawerShow: false,
  billNumber: 0,
  mobileTableDrawer: false,
  tableName: null,
  currentDataBillNo: 1,
  userDetail: {},
  sideBarMenu: [],
  updateBillData: {},
  headerMenu: [],
  serviceViceSearch: "rate",
  rateChangeHistoryPayload: {},
  cashCounterSaleSummaryPayload: {},
  urlHeaderMenu: [
    {
      systemFuctionName: "Items Information",
      route: routePath.ITEMSERVICES,
      // icons: ProductionQuantityLimitsIcon,
    },
    { systemFuctionName: "Stock", route: routePath.STOCKS },
    { systemFuctionName: "Purchase", route: routePath.PURCHASE },
    { systemFuctionName: "Payment Receipt", route: routePath.PAYMENTRECEIPT },
    {
      systemFuctionName: "Outstanding Statement",
      route: routePath.OUTSTANDINGREPORT,
    },
    { systemFuctionName: "Cash Sale Bill", route: routePath.COUNTERSELL },
    { systemFuctionName: "Expenses", route: routePath.EXPENCE },
  ],
  urlSideBarMenu: [
    {
      systemFuctionName: "My Profile",
      route: routePath.MYPROFILE,
      icon: "PersonIcon",
    },
    {
      systemFuctionName: "Business Information",
      route: routePath.MYBUSSINESS,
      icon: "StorefrontIcon",
    },
    {
      systemFuctionName: "Table Master",
      route: routePath.TABLEMASTER,
      icon: "TableBarIcon",
    },
    {
      systemFuctionName: "Table Section",
      route: routePath.TABLESECTION,
      icon: "TableRestaurantIcon",
    },
    { systemFuctionName: "KYC Documents", route: routePath.KYCPROCESS, icon: "SearchIcon" },
    {
      systemFuctionName: "Associate Users",
      route: routePath.ASSOCIATEDUSERS,
      icon: "HandshakeIcon",
    },
    {
      systemFuctionName: "Items Categories",
      route: routePath.ITEMCATEGORIES,
      icon: "DashboardIcon",
    },
    {
      systemFuctionName: "SMB Account Recharge",
      route: routePath.SMBACCOUNTREACHARGE,
      icon: "CurrencyRupeeOutlinedIcon",
    },
    {
      systemFuctionName: "Settings",
      route: routePath.SETTING,
      icon: "SettingsOutlinedIcon",
    },
    {
      systemFuctionName: "Quick Login Methods",
      route: routePath.RESETMPIN,
      icon: "LockOutlinedIcon",
    },
    {
      systemFuctionName: "Help and Support",
      route: routePath.HELPSUPPORT,
      icon: "HeadsetMicOutlinedIcon",
    },
    {
      systemFuctionName: "Share SMB App",
      route:
        "https://play.google.com/store/apps/details?id=com.mybills.mybillsapplication&hl=en_IN&gl=US",
      icon: "ShareOutlinedIcon",
    },
    {
      systemFuctionName: "Sign Out",
      route: null,
      icon: "LogoutOutlinedIcon",
    },
  ],
};

const reducer = createSlice({
  name: "reducer",
  initialState,
  reducers: {
    setBillNo: (state, action) => {
      state.billNumber = action.payload;
    },
    setUpdateBillData: (state, action) => {
      state.updateBillData = action.payload;
    },
    setSideBarMenu: (state, action) => {
      state.sideBarMenu = action.payload;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload;
    },
    setHeaderMenu: (state, action) => {
      state.headerMenu = action.payload;
    },
    startLoading: (state, action) => {
      state.loading = true;
    },
    stopLoading: (state, action) => {
      state.loading = false;
    },
    setTableName: (state, action) => {
      state.tableName = action.payload;
    },
    setDrawerShow: (state, action) => {
      state.drawerShow = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setMobileTableDrawer: (state, action) => {
      state.mobileTableDrawer = action.payload;
    },
    setServiceViceSearch: (state, action) => {
      state.serviceViceSearch = action.payload;
    },
    setRateChangeHistoryPayload: (state, action) => {
      state.rateChangeHistoryPayload = action.payload;
    },
    setCashCounterSaleSummaryPayload: (state, action) => {
      state.cashCounterSaleSummaryPayload = action.payload;
    },
  },
});

export const {
  setUserDetail,
  startLoading,
  stopLoading,
  setBillNo,
  setHeaderMenu,
  setSideBarMenu,
  setTableName,
  setDrawerShow,
  setUpdateBillData,
  setSnackbar,
  setMobileTableDrawer,
  setServiceViceSearch,
  setRateChangeHistoryPayload,
  setCashCounterSaleSummaryPayload,
} = reducer.actions;

export default reducer.reducer;
