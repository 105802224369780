import { Box, Grid, Modal, TextField } from "@mui/material";
import { searchedElementDisplay } from "./receiptFunctions";
import SearchIcon from "@mui/icons-material/Search";
import UserCard from "./userCard";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getCounterSalesCustomers } from "./paymentReceiptApi";

const PaymentReceiptSearchModal = ({
  searchModal,
  setSearchModal,
  unPaidBill,
  getUnpaid,
  setBillData,
  search,
  setSearch,
  setUnPaidBill,
  setUnPaidBillData,
  setPagination,
  billData,
  callSearchApi
  // userDetail,
}) => {
  const [searchCurrentModal, setSearchCurrentModal] = useState("")
  const getCounterSalesCusomter = async (param) => {
    await getCounterSalesCustomers(param).then((res) => {
      console.log("countersales res data   ", res?.data?.data, " ", {
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setPagination({
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setUnPaidBill([...res?.data?.data]);
    });
  };

  useEffect(() => {
    console.log("dddd")
    if (searchModal === true) {
      getCounterSalesCusomter({
        filter: "",
        search: "",
        page: 1,
        pageSize: 1000,
      });
      //   setBillData({ ...billData, mybusinessId: userDetail?.myBusinessId });
    } else {
      console.log("close");
      //   setBillData(data);
      setUnPaidBillData();

      setSearchCurrentModal("")
    }
  }, [searchModal]);
  return (
    <Modal
      open={searchModal} sx={{ zIndex: 999999 }} // Yo
      onClose={() => {
        setSearchModal(false)
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={{
        // backgroundColor: "white",
        // width:"40%"
        background: "white",
        width: "40%",
        overflowY: "auto",
        height: "70vh",
        position: "absolute",
        left: "30%",
        top: "10%",
      }}>
        <Grid item xs={12} sx={{ p: "3px" }}>
          <Box sx={{ mt: 1, textAlign: "center" }}>
            <TextField
              sx={{ width: "90%" }}
              size="small"
              label="Search by Name/Mobile Number"
              value={searchCurrentModal}
              onChange={(e) => {
                // getPaymentRec(
                //   { 
                //       "page": 1,
                //       "pageSize": 10,
                //       "filter": e.target.value,//"filter": null,
                //       "search": "",
                //       "date": null

                //   },
                //   getPaymentReceipt,
                //   setNumberOfRecords,
                //   setTotals,
                //   totals,
                //   setReceipts
                // );
                setSearchCurrentModal(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <SearchIcon
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                    }}
                  />
                ),
              }}
            />
            <CloseIcon sx={{ float: "right", cursor: "pointer", color: (theme) => theme.palette.primary.main, }} onClick={() => setSearchModal(false)} />
          </Box>
          <Box sx={{ height: "55vh", overflowY: "auto" }}>
            {unPaidBill.map((item, index) => {
              if (searchedElementDisplay(item, searchCurrentModal)) {
                return null;
              }
              return (
                <UserCard
                  data={item}
                  callBack={() => {
                    // getUnpaid({
                    //   customerMobile: item?.mobile,
                    // });
                    // setBillData({
                    //   ...item,
                    //   customerMobile: item?.mobile,
                    //   customer: item?.name,
                    // });
                    setSearch(item?.name)
                    callSearchApi(item?.name)
                    setSearchModal(false)
                  }}
                />
              );
            })}
          </Box>

        </Grid>
      </Grid>
    </Modal>
  )
}

export default PaymentReceiptSearchModal;