import { swalAlertPopProps } from "../../utils/commonUtils";

export const validationUpdateProfile = (object,Swal) => {
    if (object.name?.trim() === "") {
      Swal.fire({
        text: "Please Fill Name Field",
        icon: "error",
      });
      return false;
    }
    if (object.address?.trim() === "") {
      Swal.fire({
        text: "Please Fill Address Field",
        icon: "error",
        
        ...swalAlertPopProps,
      });
      return false;
    }
    return true;
  };