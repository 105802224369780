import store from "../../app/store/store";
import { ADDITEMS, GETITEMS, UPDATEITEMS } from "../../utils/api";
import axios from "../../utils/axios";

export const getItems = async ({ pagination, search, pageSize }) => {
  const { userDetail } = await store.getState().reducer;

  const res = await axios.post(GETITEMS, {
    myBusinessId: userDetail.myBusinessId,
    itemCategoryId: 0,
    isCounterSalesItem: true,
    page: pagination.page,
    pageSize: pageSize,
    filterByColumn: "",
    filterValue: "",
    searchByColumn: search ? "itemDescription" : "",
    searchValue: search || "",
    orderByColumn: "",
    orderBy: "",
  });
  return res;
};

export const addItem = async (payload, isEdit) => {
  const { userDetail } = await store.getState().reducer;
  const res = await axios.post(isEdit ? UPDATEITEMS : ADDITEMS, {
    ...payload,
    myBusinessId: userDetail.myBusinessId,
  });
  return res;
};
