import * as React from "react";
import Box from "@mui/material/Box";

import ReceiptIcon from "@mui/icons-material/Receipt";

import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import logo from "../assets/headers/logoSMB.png";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Fab, Tooltip } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { routePath } from "../constants/routePath";
import LogoutIcon from "@mui/icons-material/Logout";
import { setDrawerShow, setTableName } from "../app/store/reducer";
import MenuIcon from "@mui/icons-material/Menu";
import Timer from "./timer";
import { setActiveHeader } from "./commonUtils/commonUtilFunction";
import { useState } from "react";
// smbfavicon
import smbfavicon from "../assets/smbfavicon.png"
import LogOutPop from "./logoutPop";
// import LogOutPop from "./logoutPop";
import { DrawerTableBills } from "./drawerTableBills";
export default function Header({ menuLoginHeader, setDrawerShowSideBar }) {
  const location = useLocation();

  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  const urlHeaderMenu = useSelector((state) => state.reducer.urlHeaderMenu);
  const drawerShow = useSelector((state) => state.reducer.drawerShow);
  console.log(urlHeaderMenu, "urlHeaderMenu");

  const [currentPage, setCurrentPage] = React.useState(-1);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [reportActive, setReportActive] = useState(false);
  const checkHeaderUrl = (loc) => {
    const validPaths = [
      "/item-services",
      "/stocks",
      "/purchase",
      "/payament-receipt",
      "/outstanding-report",
      "/countersell",
      "/expence",
    ];

    return validPaths.includes(loc);
  };
  React.useEffect(() => {
    console.log("lcation", location.pathname);
    if (checkHeaderUrl(location.pathname)) {
      setReportActive(false);
      setCurrentPage(setActiveHeader(headerMenu, urlHeaderMenu, location));
    } else if (location.pathname === "/reports") {
      setReportActive(true);
      setCurrentPage(-1);
    } else {
      setCurrentPage(-1);
    }
  }, [location.pathname]);
  // React.useEffect(() => {
  //   console.log("lcation", location.pathname);
  //   if (checkHeaderUrl(location.pathname)) {
  //     setReportActive(false);
  //     setCurrentPage(setActiveHeader(headerMenu, urlHeaderMenu, location));
  //   } else if (location.pathname === "/reports") {
  //     setReportActive(true);
  //     setCurrentPage(-1);
  //   } else {
  //     setCurrentPage(-1);
  //   }
  // }, []);

  return (
    <Box sx={{ flexGrow: 1, borderBottom: "1px solid #edebed" }}>
      <Grid container spacing={0} sx={{ p: "0px 0px", height: "60px" }}>
        <Grid
          item
          xs={12}
          sm={1.1}
          md={1.1}
          lg={1.1}
          sx={{
            borderRight: "0.2px solid #e5e5e5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            cursor: "pointer",
            height: "60px",
          }}
          onClick={() => {
            if (location.pathname !== routePath.LOGIN) navigate("/");
          }}
        >
          {/* logo */}
          <img src={smbfavicon} alt="logo" style={{ height: "32px", m: "10px" }} />
          <Typography
            color="primary"
            sx={{
              fontSize: "10px",
              fontWeight: "700",

            }}>
            SHOW MY
            <span style={{
              color: "#FF6600"
            }}> BIILS</span>
          </Typography>
          <Typography color="secondary" sx={{
            fontSize: "10px",
            fontWeight: "700",

          }}>
            {" "}
          </Typography>

        </Grid>
        {menuLoginHeader && (
          <Grid
            item
            // xs={10}

            sx={{ display: "flex", alignItems: "center" }}
          >
            {" "}
            {menuLoginHeader?.map((item, index) => (
              <Typography
                key={"menuLogin" + index}
                variant="h6"
                gutterBottom
                align="center"
                sx={{
                  margin: "0px 10px",
                }}
              >
                {item.title}
              </Typography>
            ))}
          </Grid>
        )}
        {!menuLoginHeader && (
          <Grid
            item
            sx={{
              display: "flex",

              justifyContent: "space-between",
            }}
            // xs={location.pathname === routePath.COUNTERSELL ? 9.5 : 10}
            // sm={location.pathname === routePath.COUNTERSELL ? 8.5 : 10}
            // md={location.pathname === routePath.COUNTERSELL ? 8.5 : 10}
            // lg={location.pathname === routePath.COUNTERSELL ? 9.5 : 10}
            xs={7.9}
          // sx={{
          //   display: "flex",
          //   alignItems: "center",
          //   // justifyContent: "left",
          //   height: "60px",
          //   background: "red",
          //   // background: "red",
          // }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "left",
                height: "60px",
                width: "100%",
                // background: "red",
                // background: "red",
              }}
            >
              {headerMenu?.map((item, index) => {
                return (
                  <>
                    {index === headerMenu?.length - 1 && (
                      <Box
                        bgcolor="primary.main"
                        textAlign="center"
                        sx={{
                          borderRadius: "8px",
                          // p: "0px 3px",
                          p: 0,
                          margin: "0px 5px",
                          cursor: "pointer",
                          // padding: "4px 7px",
                          // p: "0px 3px",

                          color: reportActive ? "yellow" : "white",

                          borderRadius: "8px",
                          minWidth: "4rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "2rem",
                        }}
                        onClick={() => {
                          navigate(routePath.REPORTS);
                        }}
                        boxShadow={4} // Adds shadow effect to the box
                      >
                        <Typography
                          variant="subtitle2"
                          gutterBottom
                          align="center"
                          sx={{
                            // margin: "0px 10px",
                            fontSize: "12px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            p: 0,
                            m: 0,

                          }}
                        >
                          Reports
                        </Typography>
                      </Box>
                    )}
                    <Box
                      bgcolor={
                        index === headerMenu?.length - 1
                          ? "secondary.main"
                          : "primary.main"
                      }
                      textAlign="center"
                      sx={{
                        borderRadius: "8px",
                        minWidth: "4rem",
                        // alignContent: "center",
                        // padding: "4px 7px",
                        p: "0px 3px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        margin: "0px 5px",
                        justifyContent: "center",
                        minHeight: "2.0rem",
                        // width
                        // margin: "0px 1px",
                      }}
                      onClick={() => {
                        const indexRoute = urlHeaderMenu.findIndex(
                          (it) =>
                            it.systemFuctionName === item.systemFuctionName
                        );
                        // console.log();
                        console.log(
                          "data== ",
                          urlHeaderMenu,
                          " ",
                          indexRoute
                        );
                        if (indexRoute !== -1) {
                          if (
                            urlHeaderMenu[indexRoute]?.systemFuctionName ===
                            "Cash Sale Bill" &&
                            userDetail?.businessLineCode === "CB"
                          ) {
                            // dispatch(setTableName(userDetail?.name));
                            navigate(routePath.COUNTERSELLBILLING);
                          } else {
                            navigate(urlHeaderMenu[indexRoute].route);
                          }
                          console.log(urlHeaderMenu[indexRoute].route, "url expences")
                        }
                      }}
                      boxShadow={4} // Adds shadow effect to the box
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        key={"headerMenu" + index}
                        align="center"
                        sx={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          p: 0,
                          m: 0,

                          cursor: "pointer",
                          color: currentPage === index ? "yellow" : "white",
                        }}
                      >
                        {item.regionalFunctionName}
                      </Typography>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            p: "10px",
          }}
        >
          <Box sx={{display:{lg:'flex', alignItems:'center', gap:'10px'}}}>
          <Box sx={{
            display: 'flex',
            // justifyContent: "space-around",
            alignItem: 'center',
          }}>
            <LocalPhoneIcon sx={{
              mr: "4px",
              fontSize: '21px'

            }} color="primary" />
            <Typography
              color="primary.main"
              variant="subtitle2"
              margin={0}
              fontWeight={"bold"}
            >

              {userDetail?.mobileNumber}
            </Typography>
          </Box>
          <Timer />
          </Box>

          {true && (
            <Fab
              color="primary"
              aria-label="Add to Cart"
              size="small"
              // sx={{ float: "right" , }}
              onClick={() => dispatch(setDrawerShow(!drawerShow))}
            >
              <Tooltip title="Bills">
                <ReceiptIcon />
              </Tooltip>
            </Fab>
          )}
          {/* {location.pathname === routePath.COUNTERSELLBILLING &&
            userDetail?.businessLineCode === "CB" && (
              <Fab
                color="primary"
                aria-label="Add to Cart"
                size="small"
                // sx={{ float: "right" , }}
                onClick={() => dispatch(setDrawerShow(!drawerShow))}
              >
                <Tooltip title="Bills">
                  <ReceiptIcon />
                </Tooltip>
              </Fab>
            )} */}
          <Fab
            // color="red"
            sx={{
              background: "red",
            }}
            aria-label="Add to Cart"
            size="small"
            // sx={{ float: "right" , }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <Tooltip title="Log Out">
              {/* <LogoutIcon /> */}
              <PowerSettingsNewIcon />
            </Tooltip>
          </Fab>
        </Grid>
      </Grid>
      <LogOutPop open={open} setOpen={setOpen} />

      {/* {userDetail?.businessLineCode === "CB"  && <DrawerTableBills />} */}

      {location.pathname !== routePath.COUNTERSELL && <DrawerTableBills />}


    </Box>
  );
}
