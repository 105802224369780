import { swalAlertPopProps } from "../../utils/commonUtils";

export const updateData = async (param,updateTableData,Swal,getDataa,getMasterdApi, setTable) => {
    await updateTableData(param)
      .then((res) => {
        if (res?.data?.data?.success) {
          Swal.fire({
            title: "Item Saved Successfully",
            icon: "success",
          });
          getDataa(getMasterdApi, setTable);
        } else {
          Swal.fire({
            title: res?.data?.message,
            icon: "error",
            
            ...swalAlertPopProps,
          });
        }
      })
      .catch((err) => { });
  };

  export  const getDataa = async (getMasterdApi,setTable) => {
    await getMasterdApi().then((res) => {
      setTable(res?.data?.data?.tableList);
    });
  };
 
 export const addTables = async (param,getDataa,addTable,Swal,getMasterdApi,setTable) => {
    addTable(param)
      .then((res) => {
        if (res?.data?.data?.success) {
          Swal.fire({
            title: "Item Saved Successfully",
            icon: "success",
            
            ...swalAlertPopProps,
          });
          getDataa(getMasterdApi, setTable);
        } else {
          Swal.fire({
            title: res?.data?.message,
            icon: "error",
            
            ...swalAlertPopProps,
          });
        }
      })
      .catch((err) => {});
  };