import { useDispatch, useSelector } from "react-redux";
import TableWithBox from "./table";
import { setBillNo, setDrawerShow } from "../app/store/reducer";
import { Drawer } from "@mui/material";
import { useState } from "react";
import "./drawerTableBills.css";
import { getBill } from "../features/counterSell/counterSellApiHandlers";
import { getBillCounterSell } from "../features/counterSell/counterSellApi";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { payloadDateFormat } from "../utils/commonUtils";
import { callbackHandlerCounterSell_ } from "../features/counterSell/countersellSetParameter";

export const DrawerTableBills = () => {
  const drawerShow = useSelector((state) => state.reducer.drawerShow);

  const dispatch = useDispatch();
  const [billCount, setBillCount] = useState({});
  const [bills, setBills] = useState([]);

  const billNumber = useSelector((state) => state.reducer.billNumber);
  const callbackHandlerCounterSell = callbackHandlerCounterSell_(
    getBill,
    {
      date: payloadDateFormat(),
      status: "null",
      filter: "null",
      search: "null",
      page: 1,
      pageSize: 10,
    },
    getBillCounterSell,
    Swal,
    billNumber,
    dispatch,
    setBillNo,
    setBills,
    setBillCount,
    billCount
  );

  useEffect(() => {
    // getBill(
    //   {
    //     date: payloadDateFormat(),
    //     status: "null",
    //     filter: "null",
    //     search: "null",
    //     page: 1,
    //     pageSize: 10,
    //   },
    //   getBillCounterSell,
    //   Swal,
    //   billNumber,
    //   dispatch,
    //   setBillNo,
    //   setBills,
    //   setBillCount,
    //   billCount
    // );
    callbackHandlerCounterSell.getBill.callback(
      callbackHandlerCounterSell.getBill.parameter
    );
  }, [drawerShow]);

  return (
    <Drawer
      anchor={"right"}
      open={drawerShow}
      data-testid="custom-drawer"
      onClose={() => {
        dispatch(setDrawerShow(false));
      }}
      sx={{}}
    >
      <TableWithBox
        data={bills}
        pagesCount={billCount?.totalBills}
        billApiCall={(da) => {
          // getBill(
          //   {
          //     date: da,
          //     status: "",
          //     filter: "",
          //     search: "",
          //     page: 1,
          //     pageSize: 10,
          //   },
          //   getBillCounterSell,
          //   Swal,
          //   billNumber,
          //   dispatch,
          //   setBillNo,
          //   setBills,
          //   setBillCount,
          //   billCount
          // );
          callbackHandlerCounterSell.getBill.callback({
            ...callbackHandlerCounterSell.getBill.parameter,
            payload: {
              ...callbackHandlerCounterSell.getBill.parameter.payload,
              date: da,
            },
          });
        }}
        paginationApiCall={(da, page, status, paymentMode) => {
          console.log("paymentMode ", paymentMode);
          if (paymentMode !== undefined) {
            // getBill(
            //   {
            //     date: da,
            //     status: status,
            //     filter: paymentMode,
            //     search: "null",
            //     page: page,
            //     pageSize: 10,
            //   },
            //   getBillCounterSell,
            //   Swal,
            //   billNumber,
            //   dispatch,
            //   setBillNo,
            //   setBills,
            //   setBillCount,
            //   billCount
            // );
            callbackHandlerCounterSell.getBill.callback({
              ...callbackHandlerCounterSell.getBill.parameter,
              payload: {
                ...callbackHandlerCounterSell.getBill.parameter.payload,
                date: da,
                status: status,
                filter: paymentMode,
                page: page,
                pageSize: 10,
              },
            });
          } else {
            // getBill(
            //   {
            //     date: da,
            //     status: status,
            //     filter: "",
            //     search: "",
            //     page: page,
            //     pageSize: 10,
            //   },
            //   getBillCounterSell,
            //   Swal,
            //   billNumber,
            //   dispatch,
            //   setBillNo,
            //   setBills,
            //   setBillCount,
            //   billCount
            // );
            callbackHandlerCounterSell.getBill.callback({
              ...callbackHandlerCounterSell.getBill.parameter,
              payload: {
                ...callbackHandlerCounterSell.getBill.parameter.payload,
                date: da,
                status: status,
                page: page,
                pageSize: 10,
              },
            });
          }
        }}
        billCount={billCount}
        callback={(item) => {}}
        filterData={(date, status, page) => {
          // getBill(
          //   {
          //     date: date,
          //     status: status,
          //     filter: "",
          //     search: "",
          //     page: page,
          //     pageSize: 10,
          //   },
          //   getBillCounterSell,
          //   Swal,
          //   billNumber,
          //   dispatch,
          //   setBillNo,
          //   setBills,
          //   setBillCount,
          //   billCount
          // );
          callbackHandlerCounterSell.getBill.callback({
            ...callbackHandlerCounterSell.getBill.parameter,
            payload: {
              ...callbackHandlerCounterSell.getBill.parameter.payload,
              date: date,
              status: status,
              page: page,
              pageSize: 10,
            },
          });
        }}
      />
    </Drawer>
  );
};
