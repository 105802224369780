import { Button, Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react'
import FilterIcon from '@mui/icons-material/Filter';
import { GetKYCDocuments, uploadDocumnets } from './kycProcessApi';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { swalAlertPopProps } from '../../utils/commonUtils';

const KycProcess = () => {
    const [imageContainer, setImageContainer] = useState("");
    const profileImgRef = useRef();
    const profileImgRef2 = useRef();
    const [open, setOpen] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const userDetail = useSelector((state) => state.reducer.userDetail);
    const [responseKycDetails, setResponseKycDetails] = useState({

    })

    const [payload, setPayload] = useState({
        myBusinessId: 0,
        type: "",
        base64String: ""
    })
    const getKyc = async () => {
        await GetKYCDocuments(1, 10).then((res) => { console.log(res?.data?.data[0], "res kyc"); setResponseKycDetails(res?.data?.data[0]) }).catch((err) => console.log(err))
    }
    const uploadDoc = async (params) => {
        await uploadDocumnets(params).then((res) => {

            if (res?.data?.succeeded) {

                Swal.fire({
                    text: res?.data?.message,
                    icon: "success",

                    ...swalAlertPopProps,
                });
            } else {
                Swal.fire({
                    text: res?.data?.message,
                    icon: "warning",

                    ...swalAlertPopProps,
                });

            }
        }).catch((err) => console.log(err))
    }
    useEffect(() => {
        getKyc()
    }, [])
    useEffect(() => {
        console.log(payload, "payload image");

    }, [payload])


    return (

        <Grid container  >
            <Grid xs={12} item sx={{
                display: "flex",
                justifyContent: "center",
                m: 2
            }} >
                <Typography variant='p' sx={{ color: (theme) => theme.palette.primary.main, px: 1, fontWeight: "bold" }}>
                    Status
                </Typography>
                <Typography variant='p' sx={{ border: responseKycDetails?.status === "NotVerified" ? "1px solid orange" : "", color: "orange", px: 1, fontWeight: "bold" }}>
                    Not Verified
                </Typography>
                <Typography variant='p' sx={{ border: responseKycDetails?.status === "Verified" ? "1px solid green" : "", color: "green", px: 1, fontWeight: "bold" }}>
                    Verified
                </Typography>
                <Typography variant='p' sx={{ border: responseKycDetails?.status === "Rejected" ? "1px solid red" : "", color: "red", px: 1, fontWeight: "bold" }}>
                    Rejected
                </Typography>
            </Grid>
            <Grid container xs={12} sx={{ display: "flex", placeItems: "center", flexDirection: "column" }}>
                <Typography sx={{ color: (theme) => theme.palette.primary.main }}>Shop Photo :</Typography>
                <Grid xs={6} item sx={{ border: "4px dashed #dfdfdf", width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", p: 4 }} >
                    {
                        responseKycDetails?.shopPhotoBase64 ? <img width={"50px"} src={responseKycDetails?.shopPhotoBase64} alt="shop image" srcset="" /> : <FilterIcon sx={{ fontSize: "60px", color: (theme) => theme.palette.secondary.main }} />

                    }
                    <Typography sx={{ paddingY: 2 }}>Shop front Photo of Business Place</Typography>
                    <Button
                        variant="outlined"
                        component="label"
                        onClick={() => {
                            profileImgRef.current.click();
                        }}
                    >
                        Upload File
                    </Button>
                    <input
                        type="file"
                        accept="image/*"
                        ref={profileImgRef}
                        hidden
                        onChange={(e) => {
                            var filePath = e.target.value;
                            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

                            if (!allowedExtensions.exec(filePath)) {
                                Swal.fire({
                                    text: "Invalid file type",
                                    icon: "error",

                                    ...swalAlertPopProps,
                                });
                                e.target.value = "";
                                return false;
                            }
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                const base64 = e.target.result;

                                uploadDoc({
                                    myBusinessId: userDetail?.myBusinessId,
                                    type: "Shop",
                                    base64String: base64?.split(",")[1]

                                })
                                setPayload({
                                    ...payload,
                                    base64String: base64?.split(",")[1]
                                })
                            };
                            reader.readAsDataURL(file);
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container xs={12} sx={{ display: "flex", placeItems: "center", flexDirection: "column" }}>
                <Typography sx={{ color: (theme) => theme.palette.primary.main }}>Bank Document :</Typography>
                <Grid xs={6} item sx={{ border: "4px dashed #dfdfdf", display: "flex", width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center", p: 4 }} >
                    {
                        responseKycDetails?.cancelledChequeBase64 ? <img width={"50px"} src={responseKycDetails?.cancelledChequeBase64} alt="cheque image" srcset="" /> : <FilterIcon sx={{ fontSize: "60px", color: (theme) => theme.palette.secondary.main }} />

                    }
                    <Typography sx={{ paddingY: 2 }}>Photo of cheque or 1st page of passbook</Typography>
                    <Button
                        variant="outlined"
                        component="label"
                        onClick={() => {
                            profileImgRef2.current.click();
                        }}
                    >
                        Upload File

                    </Button>
                    <input
                        type="file"
                        accept="image/*"
                        ref={profileImgRef2}
                        hidden
                        onChange={(e) => {
                            var filePath = e.target.value;
                            var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

                            if (!allowedExtensions.exec(filePath)) {
                                Swal.fire({
                                    text: "Invalid file type",
                                    icon: "error",

                                    ...swalAlertPopProps,
                                });
                                e.target.value = "";
                                return false;
                            }
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                const base64 = e.target.result;

                                uploadDoc({
                                    myBusinessId: userDetail?.myBusinessId,
                                    type: "Cheque",
                                    base64String: base64?.split(",")[1]

                                })
                                setPayload({
                                    ...payload,
                                    base64String: base64?.split(",")[1]
                                })
                            };
                            reader.readAsDataURL(file);
                        }}
                    />

                </Grid>
            </Grid>
        </Grid>
    )
}

export default KycProcess;