export const datePickerOnChange = (date,datePickerRef2,setDate,setstartDateFormat,setSelectedStartDate) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };

 export const datePickerOnChangeEnd = (date,datePickerRef2,setSelectedEndDate,setDisplayEndDate,setEndDate) => {
    const dateStr = date?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2?.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };