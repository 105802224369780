import { swalAlertPopProps } from "../../utils/commonUtils";

export const getData = async (GetUserProfileApi,setProfileData,getUserProfileImg) => {
    await GetUserProfileApi().then((res) => {
      setProfileData(res.data.data);
    });
    await getUserProfileImg()
      .then((res) => {
        // setProfileData({...profileData,fileBase64:})
      })
      .catch((err) => {});
  };

  export const updateProfile = async (validationUpdateProfile,profileData,Swal,UpdateUserProfileApi,GetUserProfileApi,setProfileData,getUserProfileImg,UpdateUserProfileImageApi,updateProfile) => {
    if (validationUpdateProfile(profileData, Swal)) {
      await UpdateUserProfileApi({
        name: profileData?.name,
        address: profileData?.address,
      })
        .then((res) => {
          if (res?.data?.succeeded) {
            Swal.fire({
              text: "Update Successfully",
              icon: "success",
              
              ...swalAlertPopProps,
            });
          } else {
            Swal.fire({
              title: res?.data?.message,
              icon: "error",
              
              ...swalAlertPopProps,
            });
          }

          getData(GetUserProfileApi, setProfileData, getUserProfileImg);
        })
        .catch(() => {});
      if (profileData.fileBase64) {
        UpdateUserProfileImageApi({
          fileBase64: profileData.fileBase64,
        })
          .then((res) => {
            if (res.data.succeeded) {
              Swal.fire({
                title: "Image is uploaded",
                text: "",
                icon: "success",
                
                ...swalAlertPopProps,
              });
            } else {
              Swal.fire({
                title: res?.data?.message,
                icon: "error",
                
                ...swalAlertPopProps,
              });
            }
          })
          .catch(() => {
            Swal.fire({
              title: "Image not uploaded",
              text: "some thing wrong",
              icon: "error",
              
              ...swalAlertPopProps,
            });
          });
      }
    }
  };