export const callbackHandlerGetPurchase = (
    getPurchase,
    param,
    setNumberOfRecords,
    setPurchaseItems,
    getPurchaseDetail,
    setTotalAmount,
) => {
    return {
        getPurchase: {
            callback: getPurchase,
            parameter: {
                param: param,
                setNumberOfRecords: setNumberOfRecords,
                setPurchaseItems: setPurchaseItems,
                getPurchaseDetail: getPurchaseDetail,
                setTotalAmount: setTotalAmount,

            }
        }

    }
}

// getPurchase(
//     {
//       fromDate: startDateFormat,
//       toDate: endDate,
//       search: "string",
//       page: 1,
//       pageSize: 4,
//     },
//     setNumberOfRecords,
//     setPurchaseItems,
//     getPurchaseDetail, setTotalAmount