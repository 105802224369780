import {
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
export default function PurchaseItemCard({
  index,
  item,
  arr,
  setPurchaseItemsArr,
}) {
  console.log("item ", item);
  return (
    <Card
      sx={{
        width: "100%",
        m: "0px",
        mt: "10px",
        p: "0px",

        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
      }}
    //   onClick={callBack}
    //   onClick={setEditedItem}
    >
      <CardContent sx={{ p: "0px !important", display: "flex", justifyContent: "space-between" }}>
        {/* <Grid container> */}
        {/* <Grid item xs={3}> */}
        <Typography
          color="primary"
          sx={{
            fontSize: "15px",
            // overflow: "Hidden",
            // textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          }}
        >
          {item?.description}
        </Typography>
        {/* </Grid> */}
        {/* <Grid item xs={2}> */}
        <Typography
          color="black"
          sx={{
            fontSize: "15px",
            overflow: "Hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          }}
        >
          {item?.quantity}
        </Typography>
        {/* </Grid> */}
        {/* <Grid item xs={3} sx={{
            display: "flex",
            alignItems: "center"
          }}> */}

        <Typography
          color="black"
          sx={{
            fontSize: "15px",
            overflow: "Hidden",
            textOverflow: "ellipsis",
            // textAlign: "center",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          }}
        >
          <CurrencyRupeeIcon color="black" sx={{
            fontSize: "15px",
            mt: "5px",
          }} />{item?.price.toFixed(2)}
        </Typography>
        {/* </Grid> */}
        {/* <Grid item xs={3}
            sx={{
              display: "flex",
              alignItems: "center"
            }}> */}

        <Typography
          color="black"
          sx={{
            fontSize: "15px",
            overflow: "Hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": "1",
            "-webkit-box-orient": "vertical",
          }}
        >

          <CurrencyRupeeIcon color="black" sx={{
            fontSize: "15px"
          }} />{item?.amount?.toFixed(2)}
        </Typography>
        {/* </Grid>{" "} */}
        {/* <Grid item xs={1}> */}
        <DeleteIcon
          onClick={() => {
            let arr1 = [...arr];
            arr1.splice(index, 1);
            setPurchaseItemsArr(arr1);
          }}
        />
        {/* </Grid> */}
        {/* </Grid> */}
      </CardContent>
    </Card>
  );
}
