export const getPaymentRec = async (param,getPaymentReceipt,setNumberOfRecords,setTotals,totals,setReceipts) => {
    await getPaymentReceipt(param)
      .then((res) => {
        console.log("get payment receipts ", res?.data);
        setTotals({
          ...totals,
          totalReceipts: res?.data?.data?.totalReceipts,
          totalRecovery: res?.data?.data?.totalRecovery,
        });
        setNumberOfRecords(res?.data?.data?.totalReceipts);
        setReceipts(res?.data?.data?.paymentReceipts);
      })
      .catch((err) => {});
  };

export  const getCounterSalesCusomter = async (
    param,
    getCounterSalesCustomers,
    setPagination, 
    ) => {
    await getCounterSalesCustomers(param).then((res) => {
      console.log(" res data   ", res?.data?.data, " ", {
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setPagination({
        page: res.data.pageNumber,
        total: res.data.totalPages,
      });
      setPagination([...res?.data?.data]);
    });
  };