import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Button, Modal } from "@mui/material";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { ApplyAccordingREAndCBUser } from "../features/counterSellBilling/counterSellBillFunctions";
export default function KotPrint({
  open2,
  handleClose2,
  componentRef,
  kotItem,
  tableName,
  setKOT,
  setBill,
  setOpen2,
  addtoCardProductCBUser,
}) {
  const userDetail = useSelector((state) => state.reducer.userDetail);
  console.log("kot ", kotItem, " ", open2);
  return (
    // <Modal
    //   open={open2}
    //   onClose={handleClose2}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Box
      sx={{
        width: userDetail?.printerSize == 3 ? "80mm" : "48mm",
        background: "white",
        display: "none",
        // width: "302.36px",

        position: "absolute",
        left: "30%",
        p: "10px",
        top: "30%",
        overflowY: "auto",
        maxHeight: "60vh",
      }}
    >
      <Box
        ref={componentRef}
        sx={{
          minWidth: userDetail?.printerSize == 2 ? "48mm" : "none",
          //3 inch
          // maxWidth: userDetail?.printerSize == 3 ? "80mm" : "none", // Adjust the maximum width to fit the print paper size
          maxWidth: userDetail?.printerSize == 3 ? "80mm" : "none", // Adjust the maximum width to fit the print paper size
          margin: userDetail?.printerSize == 3 ? "10px" : "0px",
          // minWidth: userDetail?.printerSize == 2 ? "80mm" : "none",
          // //3 inch
          // // maxWidth: userDetail?.printerSize == 3 ? "80mm" : "none", // Adjust the maximum width to fit the print paper size
          // maxWidth: userDetail?.printerSize == 3 ? "48mm" : "none", // Adjust the maximum width to fit the print paper size
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            margin: "0px",
            lineHeight: "1.2",
            p: "0px",
            fontSize: "20px",
          }}
        >
          KOT
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            margin: "0px",
            mt: "-2px",
            lineHeight: "1.2",
            p: "0px",
            fontSize: "20px",
          }}
        >
          {" "}
          {ApplyAccordingREAndCBUser(
            userDetail,
            () => {
              return tableName;
            },
            () => ""
          )}
        </Typography>
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            margin: "0px",
            lineHeight: "1.2",
            p: "0px",
            // fontSize: "15px",

            m: "0px 10px 0px 0px",
            fontSize: userDetail?.printerSize == 3 ? "15px" : "11px",
          }}
        >
          {(function () {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, "0"); // Zero padding for day
            const monthNames = [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ];
            const month = monthNames[date.getMonth()];
            const year = String(date.getFullYear()); // Extract last 2 digits of the year
            const formattedDate = `${day}-${month}-${year}`;
            return formattedDate;
          })()}
          {"  "}
          {(function () {
            const padZero = (value) => {
              return value.toString().padStart(2, "0");
            };
            const date = new Date();
            const hour = date.getHours() % 12 || 12;
            const minute = date.getMinutes();
            const second = date.getSeconds();
            const ampm = date.getHours() >= 12 ? "PM" : "AM";
            const formattedTime = `${hour}:${padZero(minute)} ${ampm}`;
            return formattedTime;
          })()}
        </Typography>
        <Box
          sx={
            {
              // p: "10px",
            }
          }
        >
          <Grid container>
            <Grid
              item
              container
              sx={{
                borderBottom: "1px dashed black",
                borderTop: "1px dashed black",
              }}
            >
              <Grid item xs={9} sx={{ fontSize: "14px" }}>
                ITEM
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "left", fontSize: "14px" }}>
                QTY
              </Grid>
            </Grid>
            {ApplyAccordingREAndCBUser(
              userDetail,
              () => {
                return kotItem;
              },
              () => {
                return addtoCardProductCBUser.filter(
                  (item) => item?.printQty > 0
                );
              }
            )?.map((ele, index) => {
              return (
                <React.Fragment key={"kotlist" + index}>
                  <Grid
                    item
                    container
                    // sx={{ borderBottom: "1px dashed black" }}
                  >
                    <Grid item xs={9} sx={{ fontSize: "14px" }}>
                      {/* {ele?.itemDescription} */}
                      {ApplyAccordingREAndCBUser(
                        userDetail,
                        () => {
                          return ele?.itemDescription;
                        },
                        () => {
                          return ele?.itemDescription;
                        }
                      )}
                      {}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{ textAlign: "left", fontSize: "14px" }}
                    >
                      {ele?.printQty}
                      {/* 123333 */}
                    </Grid>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "center",
              // fontWeight: "500",
              borderTop: "1px solid black",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
              }}
            >
              THANK YOU
            </Typography>
          </Grid>
        </Box>
      </Box>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            sx={{
              // mb: "10px",
              width: "100%",
              mt: "10px",
            }}
            onClick={() => {
              setKOT();
              setOpen2(false);
            }}
          >
            {" "}
            Print
          </Button>
        )}
        content={() => componentRef.current}
        onBeforePrint={() => {
          setKOT();
          setOpen2(false);
        }}
      ></ReactToPrint>
    </Box>
  );
}
