export const validationsSubmits = (MyBusinessData) => {
  const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  if (!pattern.test(MyBusinessData?.gstRegistrationNumber)) {
    return { status: false, msg: "Please Enter Valid Gst Registration number" };
  } else if (MyBusinessData?.enterpriseName?.replace(/\s/g, "").length === 0) {
    return { status: false, msg: "Please Enter Enterprise Name" };
  } else if (MyBusinessData?.address?.trim() === "") {
    return { status: false, msg: "Please Enter Address" };
  } else if (MyBusinessData?.upiid?.trim() === "") {
    return { status: false, msg: "Please Enter UPI ID" };
  } else if (MyBusinessData?.accountNumber?.trim() === "") {
    return { status: false, msg: "Please Enter Account Number" };
  } else if (MyBusinessData?.ifsc?.trim() === "") {
    return { status: false, msg: "Please Enter IFSC Number" };
  } else if (MyBusinessData?.referenceId?.trim() === "") {
    return { status: false, msg: "Please Enter Reference Id Number" };
  }
  return {
    status: true,
    msg: "",
  };
};
