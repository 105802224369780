import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import React, { useEffect, useState } from "react";
import DatePicker from "./DatePicker";
import dayjs from "dayjs";
import { addItem } from "./itemApi";

import CloseIcon from "@mui/icons-material/Close";

const data = {
  itemDescription: "",
  unit: "",
  price: "",
  fromDate: dayjs(),
  status: "",
  isCounterSalesItem: true,
  itemCategoryId: 0,
  myBusinessId: 0,
  serviceFrequency: null,
  daysOfService: null,
  gst: 0.0,
  businessLineId: 0,
  franchiseItemMasterId: 0,
  maintainInventory: false,
  itemServiceTypeAssn: null,
};

export default function AddItem({ open, item, onSave, setOpen }) {
  const onClose = () => {
    setItemData(data);
    setOpen(false);
  };
  const [itemData, setItemData] = useState(data);

  useEffect(() => {
    if (item) {
      setItemData(item);
    }
  }, [item]);

  const onChange = (key, value) => {
    setItemData({ ...itemData, [key]: value });
  };


  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
      <DialogTitle
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: 0,
          p: "3px"
        }}
      >
        Item Information{" "}
        <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: "3px" }}>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.itemDescription}
            onChange={(e) => {
              onChange("itemDescription", e.target.value);
            }}
            label={
              <span>
                Item Description<span style={{ color: "red" }}>*</span>
              </span>
            }
          />
          <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={itemData.price}
              onChange={(e) => {
                if (!isNaN(Number(e.target.value)))
                  onChange("price", e.target.value);
              }}
              label={
                <span>
                  Rate (₹)<span style={{ color: "red" }}>*</span>
                </span>
              }
            />
            {/* <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={itemData.unit}
              onChange={(e) => {
                onChange("unit", e.target.value);
              }}
              label={
                <span>
                  Unit<span style={{ color: "red" }}>*</span>
                </span>
              }
            /> */}
             <FormControl fullWidth size="small" variant="outlined">
      <InputLabel id="unit-select-label">
        <span>
          Unit<span style={{ color: "red" }}>*</span>
        </span>
      </InputLabel>
      <Select
        labelId="unit-select-label"
        id="unit-select"
        value={itemData.unit}
        label={
          <span>
            Unit<span style={{ color: "red" }}>*</span>
          </span>
        }
        onChange={(e) => {
          onChange("unit", e.target.value);
        }}
      >
        <MenuItem value={"Cup"}>Cup</MenuItem>
        <MenuItem value={"Nos"}>Nos</MenuItem>
        <MenuItem value={"Plate"}>Plate</MenuItem>
        <MenuItem value={"Kg"}>Kg</MenuItem>
        <MenuItem value={"Bottle"}>Bottle</MenuItem>
        <MenuItem value={"Glass"}>Glass</MenuItem>
        <MenuItem value={"Dish"}>Dish</MenuItem>
      </Select>
    </FormControl>
          </Stack>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.gst ? itemData.gst : ""}
            onChange={(e) => {
              if (!isNaN(Number(e.target.value)))
                onChange("gst", e.target.value);
            }}
            label="GST(%)"
            sx={{ mt: 1 }}
          />
          {item && (
            <FormGroup>
              <FormControlLabel
                sx={{
                  justifyContent: "space-between",
                  m: 0,
                  flexDirection: "row-reverse",
                }}
                control={
                  <Switch
                    checked={itemData.status !== "active"}
                    onChange={(e) => {
                      onChange(
                        "status",
                        e.target.checked ? "inactive" : "active"
                      );
                    }}
                  />
                }
                label="Discontinue"
              />
            </FormGroup>
          )}
          <DatePicker
            date={itemData.fromDate}
            // size="small"

            sx={{ height: "30px" }}
            onChange={(date) => {
              onChange("fromDate", date);
            }}
            isEdit={!!item}
          />
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="top"
                control={
                  <Checkbox
                    checked={itemData.maintainInventory}
                    onChange={(e) => {
                      onChange("maintainInventory", e.target.checked);
                    }}
                  />
                }
                label="Maintain Inventory"
                labelPlacement="right"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            itemData.itemDescription.trim().length === 0 ||
            itemData.price.toString().trim().length === 0 ||
            itemData.unit.trim().length === 0
          }
          variant="contained"
          onClick={() => {
            addItem(
              {
                ...itemData,
                fromDate: dayjs(itemData.fromDate).format("YYYY-MM-DD"),
                gst: Number(itemData.gst) || 0.0,
                price: Number(itemData.price),
                status: itemData.status || "active",
              },
              !!item
            ).then(() => {
              onSave && onSave();
            });
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
