import { Box, Button, Typography, Modal, Grid } from "@mui/material";

import React, { useEffect, useState } from "react";

import { getNewSubscriptionReachageDetails } from "./smbAccountReachageApi";
import { payloadDateFormat } from "../../utils/commonUtils";
import { getDataRechageDetail } from "./smbAccountRechargeCustomApiHandler";
import { getMonthDifference } from "./smbAccounrReachargeDateFunction";
import CloseIcon from '@mui/icons-material/Close';
export default function DisplayAnnualRecharge({ open, setOpen, modalData }) {
  const [pagination, setPagination] = useState({ page: 1, total: 10 });
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    if (open === true) {
      getDataRechageDetail(getNewSubscriptionReachageDetails, setSubscription);
    } else {
      console.log("close");
    }
  }, [open]);
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Box sx={{textAlign:'right'}}>
        <CloseIcon onClick={onClose} sx={{color: (theme) => theme.palette.primary.main, cursor:'pointer'}}/>
        </Box>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "24px",
            }}
          >
            {" "}
            Recommanded Plans for You
          </Typography>
          <Typography
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: "17px",
            }}
          >
            {" "}
            As per present count of your customers, available plans are:
          </Typography>
        </Grid>
        {subscription?.map((item, index) => {
          return (
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  width:'auto',
                  p: "10px",
                  mt: "20px",
                  borderRadius: "5px",
                  border: "1px solid black",
                  textAlign: "center",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.secondary.main,
                    fontWeight: "600",
                  }}
                >
                  {item?.smbPackage}
                </Typography>
                <Typography
                  sx={{
                    color: "red",
                    fontSize: "12px",
                  }}
                >
                  SAVE {item?.discount} %
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    Amount :
                  </Typography>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: 600,
                    }}
                  >
                    {item?.totalAmountWithCGST}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                    }}
                  >
                    Validity :
                  </Typography>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      fontWeight: 600,
                    }}
                  >
                    {item?.validity}
                    {} Month
                  </Typography>
                </Box>
                <Button variant="contained" sx={{ width: "100%", mt: "20px" }}>
                  RECHARGE NOW
                </Button>
                {/* </Grid> */}
              </Box>
            </Grid>
          );
        })}
      </Box>
    </Modal>
  );
}
