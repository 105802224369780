
// import {    GETCOUNTERSALESREPORT, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS } from " ../../utils/api";
import { ADDEXPENSE, GETDETAILSEXPENSE, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS, GETPURCHASEDETAILS, PURCHASEADD, UPDATEEXPENSE } from "../../utils/api";
import axios from "../../utils/axios"; 

export const GetDetailsExpense=async(param)=>{
    const res=await axios.post(GETDETAILSEXPENSE,param);
    return res;
}

export const addExpense=async(param)=>{
    const res=await axios.post(ADDEXPENSE,param);
    return res;
    
}
export const updateExpense=async(param)=>{
    const res=await axios.post(UPDATEEXPENSE,param)
    return res;
}