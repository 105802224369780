import React from 'react'
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Footerlogo from "../../assets/icon/footericon.png";
import { Link } from "react-router-dom";
import logo from "../../assets/headers/logoSMB.png";

export const Footer = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>

      <Box backgroundColor={"#04091d"} py={10} id="contact">
        <Container>
          <Typography color={"#e8410b"} fontSize={"61px"} lineHeight={1}>
            CONTACT US
          </Typography>
          <Grid container>
            <Grid item md={6} sm={12} xs={12}>
              <Box color={"#fff"} display={"flex"} flexDirection={"column"}>
                <Typography pb={3}>
                  301, Ritika Residency, Nisarg Pooja Mankar Chowk, Wakad
                  Pune-411057
                </Typography>
                <Link
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={() => (window.location = "tel:+919209054709")}
                >
                  Phone: +91-9209054709
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={() => (window.location = "support@showmybills.com")}
                >
                  Email: support@showmybills.com
                </Link>
                <Box
                  pt={3}
                  component={"img"}
                  src={logo}
                  alt="logo"
                  width={"300px"}
                />
                <Typography color={"#00c6c0"} mt={1}>
                  "Billing Made Easy, Anytime, Anywhere"
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12} color={"#fff"} sx={{ marginTop: { xs: '50px', sm: '100px', md: '0px' } }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>USEFUL LINKS</Typography>
                  <List>
                    <ListItem sx={{ pl: "0",cursor:"pointer" }}>
                      <ListItemText
                        sx={{ color: "#00c6c0 !important" }}
                        primary="Home"
                        onClick={()=>{
                          scrollToSection("home");
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ pl: "0",cursor:"pointer" }}>
                      <ListItemText
                        sx={{ color: "#00c6c0 !important" }}
                        primary="About US"
                        onClick={()=>{
                          scrollToSection("about");
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ pl: "0",cursor:"pointer" }}>
                      <ListItemText
                        sx={{ color: "#00c6c0 !important" }}
                        primary="Benefits"
                        onClick={()=>{
                          scrollToSection("benefits");
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ pl: "0",cursor:"pointer" }}>
                      <ListItemText
                        sx={{ color: "#00c6c0 !important" }}
                        primary="Download App"
                        onClick={()=>{
                          
                          window.open(`https://play.google.com/store/apps/details?id=com.mybills.mybillsapplication&hl=en&gl=US&pli=1`, "_blank");
                        }}
                      />
                    </ListItem>
                  </List>

                  <Typography pb={"1em"}>Social Media</Typography>
                  <Box display={"flex"}>
                    <Box
                      sx={{
                        mr: "5px",
                        width: 40,
                        height: 40,
                        backgroundColor: "#25d366",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <WhatsAppIcon onClick={() => {

                        const whatsappLink = `https://web.whatsapp.com/send?phone=${9209054709}&text=Hello`;
                        window.open(whatsappLink, "_blank");
                      }} />
                    </Box>
                    <Box
                      sx={{
                        mx: "5px",
                        width: 40,
                        height: 40,
                        backgroundColor: "#e4405f",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <InstagramIcon onClick={() => {

                         
                        window.open(`https://www.instagram.com/showmybills/`, "_blank");
                      }} />
                    </Box>
                    <Box
                      sx={{
                        mx: "5px",
                        width: 40,
                        height: 40,
                        backgroundColor: "#3b5998",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    > 
                      <FacebookIcon 
                      onClick={() => { 
                        window.open(`https://www.facebook.com/ShowMyBill`, "_blank");
                      }} />
                    </Box>
                    <Box
                      sx={{
                        mx: "5px",
                        width: 40,
                        height: 40,
                        backgroundColor: "#ff0000",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                    >
                      <YouTubeIcon 
                       onClick={() => { 
                        window.open(`https://www.youtube.com/channel/UC-idwviLCoMwXqfYYJjmcSw`, "_blank");
                      }}/>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} position={"relative"}>
                  <Box
                    position={"absolute"}
                    sx={{ top: { xs: '0px', sm: "-70px", md: "-90px" }, width: { xs: '100%', sm: "75%", md: "100%" } }}
                    component={"img"}
                    src={Footerlogo}
                    alt="icon"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box backgroundColor={"#bbc9d6"} py={3}>
        <Container>
          <Typography fontWeight={"bold"}>
            © Copyright Show My Bills Private Limited.
          </Typography>
        </Container>
      </Box>
    </>
  )
}
