import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { convertDateToCustomFormat } from "../../utils/commonUtils";
export default function OutStandingReportCart({ item, setOpen, setModalData }) {
  return (
    <Card
      sx={{
        width: "100%",
        // m: "0px",
        // mt: "10px",
        ":hover": {
          bgcolor: "#2892ba17",
        },
        cursor: "pointer",
        // p: "0rem 0.3rem",
      }}
      onClick={() => {
        setOpen(true);
        setModalData(item);
      }}
    >
      <CardContent sx={{ padding: "0.5px 10px !important" }}>
        <Grid container>
          <Grid item xs={4}>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {item?.billNumber}
            </Typography>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",

                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                // fontWeight: 600,
              }}
            >
              {item?.buyerMobile}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color="primary" sx={{ fontSize: "14px" }}>
              {convertDateToCustomFormat(item?.billDate?.split("T")[0])}
            </Typography>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {item?.buyerName}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              {item?.customerMobile}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: "14px" }} color="primary" >
              Days Elapsed:{item?.daysElapsed}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <CurrencyRupeeIcon color="secondary" fontSize="10px" />
              <Typography
                color="secondary"
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  fontWeight: 600,
                }}
              >
                {item?.balanceAmount?.toFixed(2)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
