import { payloadDateFormat } from "../../utils/commonUtils";

export const callbackHandlerCounterSell_ = (
  getBill,
  payload,
  getBillCounterSell,
  Swal,
  billNumber,
  dispatch,
  setBillNo,
  setBills,
  setBillCount,
  billCount,
  getData,
  TableSectionListApi,
  setSections,
  tableListApi,
  setSectionPresent,
  setTables
) => {
  return {
    getBill: {
      callback: getBill,
      parameter: {
        payload: payload,
        getBillCounterSell: getBillCounterSell,
        Swal: Swal,
        billNumber: billNumber,
        dispatch: dispatch,
        setBillNo: setBillNo,
        setBills: setBills,
        setBillCount: setBillCount,
        billCount: billCount,
      },

      //   [
      //     {
      //       date: payloadDateFormat(),
      //       status: "null",
      //       filter: "null",
      //       search: "null",
      //       page: 1,
      //       pageSize: 10,
      //     },
      //     getBillCounterSell,
      //     Swal,
      //     billNumber,
      //     dispatch,
      //     setBillNo,
      //     setBills,
      //     setBillCount,
      //     billCount,
      //   ]
    },
    getData: {
      callback: getData,
      parameter: {
        TableSectionListApi: TableSectionListApi,
        setSections: setSections,
        tableListApi: tableListApi,
        setSectionPresent: setSectionPresent,
        setTables: setTables,
      },
    },
  };
};
