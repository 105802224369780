import {
  Card,
  CardContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
export default function UserCard({ data, callBack }) {
  return (
    <Card
      sx={{
        width: "100%",
        m: "0px",
        mt: "10px",
        p: "0px",

        cursor: "pointer",
        ":hover": {
          bgcolor: "#2892ba17",
        },
      }}
      onClick={callBack}
    //   onClick={setEditedItem}
    >
      <CardContent sx={{ p: "0px 10px !important", }}>
        <Grid container>
          <Grid item xs={8}>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {data?.name}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}> {data?.mobile}</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <CurrencyRupeeIcon color="primary" fontSize="10px" /> */}
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",
                margin: "10px",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {/* {data?.headerRecordId} */}
            </Typography>
            <Typography
              color="primary"
              sx={{
                fontSize: "15px",
                overflow: "Hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical",
                fontWeight: 600,
              }}
            >
              {/* {data?.netAmount} */}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
