import { Box, Button, Switch, TextField, Typography } from "@mui/material";

import Grid from "@mui/material/Grid";

import React, { useEffect, useState } from "react";

import { } from "../../utils/utils";

import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { GetBussiness, UpdateBusiness } from "./myBusinessApi";
import { formDataFn, swalAlertPopProps } from "../../utils/commonUtils";
import { validationsSubmits } from "./myBusinessValidationFunctions";
import { FormGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import ImageUploader from "../../common/imageUpload";
export default function MyBusiness() {
  const [MyBusinessData, setMyBussinessData] = useState(null);

  const userDetail = useSelector((state) => state.reducer.userDetail);
  const getData = async () => {
    await GetBussiness().then((res) => {
      setMyBussinessData({
        ...res.data.data[0],
        EnterpriseName:res?.data?.data[0]?.enterpriseName,
        mobileNumber: userDetail?.mobileNumber,
      });
    });
  };

  const submit = async () => {
    const pattern = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    const valid = validationsSubmits(MyBusinessData);
    if (valid.status) {
     
let obj = { ...MyBusinessData };
delete obj?.enterpriseName;

// Create FormData from the updated object
let payload = formDataFn({ ...obj });

console.log("payload", payload);

// Use the payload directly in UpdateBusiness
await UpdateBusiness(payload)
        .then((res) => {
          if (res?.data?.succeeded) {
            Swal.fire({
              title: "Update Successfully",
              icon: "success",

              ...swalAlertPopProps,
            });
          } else {
            Swal.fire({
              title: res?.data?.message,
              icon: "error",

              ...swalAlertPopProps,
            });
          }

          getData();
        })
        .catch((err) => { });
    } else {
      Swal.fire({
        text: valid.msg,
        icon: "warning",

        ...swalAlertPopProps,
      });
    }
  };

  useEffect(() => {
    if (userDetail?.mobileNumber) {
      getData();
    }
  }, [userDetail]);
  useEffect(()=>{
    console.log("MyBusinessData ",MyBusinessData)
  },[MyBusinessData])
  return (
    <Box>
      {MyBusinessData && (
        <Box
          sx={{
            p: "10px",
            p: { md: "60px", sm: "40px", xs: "10px" },
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            textAlign="center"
            sx={{
              mb: "1.5rem",
            }}
            color="primary"
          >
            My Bussiness
          </Typography>

          <Grid container spacing={2} alignItems="center" padding={2}>
          <Grid item xs={12}>
              <ImageUploader path={MyBusinessData?.logoPath} callback={(file) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    File:file,
                  });
                }} /> 
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="EnterpriseName"
                label="Enterprise Name"
                value={MyBusinessData?.EnterpriseName}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    EnterpriseName: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="BrifeAddress"
                label="Brife Address"
                value={MyBusinessData?.address}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    address: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="mobileno"
                label="Mobile No"
                disabled={true}
                value={MyBusinessData?.mobileNumber}
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="UPIID"
                label="UPI ID"
                value={MyBusinessData?.upiid}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    upiid: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="BandACNumber"
                label="Bank A/C Number"
                readOnly
                value={MyBusinessData?.accountNumber}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    accountNumber: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="IFSCCODE"
                label="IFSC code of the branch"
                value={MyBusinessData?.ifsc}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    ifsc: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="GSTREGISTRATIONNUMBER"
                label="GST registration number"
                // value={language}
                value={MyBusinessData?.gstRegistrationNumber}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    gstRegistrationNumber: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                id="referenceId"
                label="Reference Id"
                value={MyBusinessData?.referenceId}
                onChange={(e) => {
                  setMyBussinessData({
                    ...MyBusinessData,
                    referenceId: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <FormControlLabel
                  sx={{
                    justifyContent: "space-between",
                    m: 0,
                    flexDirection: "row-reverse",
                  }}
                  control={
                    <Switch
                      checked={MyBusinessData?.isPrintQRCodeOnBill}
                      onChange={(e) => {
                        // onChange("isActive", e.target.checked);
                        setMyBussinessData({
                          ...MyBusinessData,
                          isPrintQRCodeOnBill: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="Do you want to print QR code on bill"
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={submit}
            sx={{ mt: 3, mb: 2 }}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}
