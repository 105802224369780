import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrinterSettin } from "./settingApi";
import { setUserDetail } from "../../app/store/reducer";
import { update } from "./settingCustomApiHandler";

const Setting = () => {
  const filters = [
    { name: "2 inch printer ", value: "2" },
    { name: " 3 inch printer ", value: "3" },
  ];
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const dispatch = useDispatch();
  const [settingPayload, setsettingPayload] = useState({
    myBusinessId: 0,
    type: "SIZE",
    value: userDetail.printerSize ? String(userDetail?.printerSize) : "3",
  });
  useEffect(() => {
    console.log("idby ", settingPayload.myBusinessId);
    if (settingPayload.myBusinessId === 0 && userDetail.myBusinessId) {
      // Update myBusinessId in settingPayload with the value from userDetail
      setsettingPayload((prevSetting) => ({
        ...settingPayload,
        myBusinessId: userDetail.myBusinessId,
      }));
    }
  }, [userDetail]);

  useEffect(() => {
    update(settingPayload, PrinterSettin, dispatch, setUserDetail, userDetail);
  }, [settingPayload]);
  return (
    <Grid container sx={{ p: "20px" }}>
      <Grid
        item
        xs={12}
        sx={{
          padding: "0px 16px",
          fontWeight: "bold",
        }}
      >
        <FormControl fullWidth sx={{}}>
          <InputLabel id="demo-simple-select-label">Select Device </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={settingPayload.value}
            label="Select Device"
            onChange={(e) => {
              console.log("SelectDevice ", e.target.value);
              dispatch(
                setUserDetail({
                  ...userDetail,
                  printerSize: e.target.value,
                })
              );
              setsettingPayload({
                ...settingPayload,
                value: e.target.value,
              });
            }}
          >
            {filters.map((item, index) => {
              return (
                <MenuItem value={item.value} key={"option" + index}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          padding: "0px 16px",
          fontWeight: "bold",
          //   display: "flex",
          alignItems: "center",

          //   justifyContent: "space-between",
        }}
      >
        <FormGroup
          sx={{
            mt: "20px",

            height: "1.4375em",
            borderRadius: "4px",

            border: "1px solid rgba(0, 0, 0, 0.87)",
            p: "7px",
            pb: "22px",
          }}
        >
          <FormControlLabel
            sx={{
              justifyContent: "space-between",
              m: 0,
              flexDirection: "row-reverse",
            }}
            control={
              <Switch
                checked={settingPayload.value === "true" ? true : false}
                onChange={(e) => {
                  setsettingPayload({
                    ...settingPayload,

                    value: String(e.target.checked),
                  });
                }}
              />
            }
            label="Print in Reginal language "
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
};
export default Setting;
