export const getData = async (param,getStockDetail,setStocks,setPagination,pagination) => {
    await getStockDetail(param)
      .then((res) => {
        console.log("res ", res);
        setStocks(res?.data?.data);
        setPagination({
          ...pagination,
          total: res?.data?.totalPages,
          records: res?.data?.totalRecords,
        });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };