export const update = async (param,PrinterSettin,dispatch,setUserDetail,userDetail) => {
    await PrinterSettin(param)
      .then((res) => {
        console.log("udpate setting ", res?.data?.data);
        dispatch(
          setUserDetail({
            ...userDetail,
            printerSize: res?.data?.data?.printerSize,
          })
        );

        // dispatch(setUserDetail(res?.data?.data));
      })
      .catch((err) => {
        console.log("errr ", err);
      });
  };
