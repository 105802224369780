import { stopLoading } from "../../app/store/reducer";
import store from "../../app/store/store";
import { swalAlertPopProps } from "../../utils/commonUtils";

export const getData = async ({
  TableSectionListApi,
  setSections,
  tableListApi,
  setSectionPresent,
  setTables,
}) => {
  await TableSectionListApi({
    isActive: true,
  })
    .then((res) => {
      let arr = [...res.data.data.tableList];
      setSections(arr);
    })
    .catch((err) => {
      console.error(err);
    });

  await tableListApi({
    sectionId: 0,
    isActive: true,
  })
    .then((res2) => {
      const index = [...res2.data.data.tableList].filter(
        (table) => table.sectionId > 0
      );
      if (index.length > 0) {
        setSectionPresent(true);
      } else {
        setSectionPresent(false);
      }

      setTables(res2.data.data.tableList);
    })
    .catch((err) => {
      console.error(err);
    });
};

export const getBill = ({
  payload,
  getBillCounterSell,
  Swal,
  billNumber,
  dispatch,
  setBillNo,
  setBills,
  setBillCount,
  billCount,
}) => {
  console.log("callbackHandlerCounterSell api ", payload);
  getBillCounterSell(payload)
    .then((res) => {
      if (res.data.data === null) {
        Swal.fire({
          text: "No Records Found",
          icon: "warning",
          ...swalAlertPopProps,
        });
        return;
      }
      console.log(
        "counterSales get all user ",
        res?.data?.data?.counterSalesHeader
      );
      if (billNumber == 0 && res?.data?.data?.counterSalesHeader.length > 0) {
        dispatch(setBillNo(res?.data?.data?.counterSalesHeader[0].billNumber));
      }
      setBills(res?.data?.data?.counterSalesHeader);
      setBillCount({
        ...billCount,
        totalBills: res?.data?.data?.totalBills,
        totalOutstanding: res?.data?.data?.totalOutstanding,
        totalSales: res?.data?.data?.totalSales,
        unpaidBills: res?.data?.data?.unpaidBills,
      });
    })
    .catch(async(err) => {
      console.log(err);
      await store.dispatch(stopLoading());
    });
};
