import React, { useRef, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EventIcon from "@mui/icons-material/Event";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";
import SearchIcon from "@mui/icons-material/Search";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";

import {
  getCounterSalesReport,
  getRateChangeHistory,
} from "./rateChangeHistoryreportsApi";
// import GstReportCard from "./gstReportCard";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import CounterSalesItemCard from "./counterItemCard";
import axios from "../../../utils/axios";
import { payloadDateFormat } from "../../../utils/commonUtils";
import { getItems } from "./rateChangeHistoryCustomApiHandler";
import { useSelector } from "react-redux";
const RateChangeItemHistory = () => {
  const [open, setOpen] = useState(false);

  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  const displayDateFormat = () => {
    var currentDate = new Date();
    let dateContainer = String(currentDate).split(" ");

    var year = dateContainer[2];
    var month = dateContainer[1];

    var day = dateContainer[3];

    var formattedDate =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

    return formattedDate;
  };
  const [gstReports, setgstReports] = useState({
    totalSales: "",
    gstReports: [],
  });
  const [counterSales, setCounterSales] = useState();
  const [billFilter, setBillFilter] = useState("cash");

  const [date, setDate] = useState(displayDateFormat);
  const [displayEndDate, setDisplayEndDate] = useState(displayDateFormat);

  const [SelectedStartDate, setSelectedStartDate] = useState(new Date());
  const [startDateFormat, setstartDateFormat] = useState(payloadDateFormat());

  const [endDate, setEndDate] = useState(payloadDateFormat());

  const datePickerRef = useRef();
  const datePickerRef2 = useRef();
  const rateChangeHistoryPayload = useSelector(
    (state) => state.reducer.rateChangeHistoryPayload
  );
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const getCounterSales = async (param) => {
    await getCounterSalesReport(param)
      .then((res) => {
        console.log("sales Report ", res?.data?.data);
        setCounterSales(res?.data?.data);
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };
  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };

  useEffect(() => {
    console.log("start date ", startDateFormat);
    console.log("display End ", endDate);
    // getCounterSales({
    //   fromDate: startDateFormat,
    //   toDate: endDate,
    // });
  }, [startDateFormat, endDate]);
  useEffect(() => {
    console.log("datE ", payloadDateFormat());
    // getCounterSales({
    //   fromDate: startDateFormat,
    //   toDate: endDate,
    // });
    if (rateChangeHistoryPayload?.itemId) {
      let payload = { ...rateChangeHistoryPayload };
      delete payload.itemName;
      getItems(payload, getRateChangeHistory);
    }
  }, [rateChangeHistoryPayload]);
  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  return (
    <>
      <Grid
        container
        sx={{
          p: "20px",
          display: "flex",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", height: "56px" }}
        >
          <TextField
            fullWidth
            disabled={true}
            id="name"
            value={rateChangeHistoryPayload?.itemName}
            onClick={() => {
              //   setOpen2(true);
            }}
            onChange={(e) => {}}
            sx={{ "& .MuiInputBase-root": { height: "45px" } }}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                  onClick={() => {
                    console.log("clicked");
                  }}
                />
              ),
            }}
          />
        </Grid>

        <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={datePickerOnChange}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          maxDate={new Date()}
          onChange={datePickerOnChangeEnd}
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2.current.setOpen(false);
          }}
        />

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                  }}
                >
                  Effective from
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Rate
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Diff.
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Cha. %
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          >
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                  }}
                >
                  Effective from
                </Typography>
              </Grid>

              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Rate
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Diff.
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Cha. %
              </Grid>
            </Grid>
          </Grid>
          {counterSales?.counterSalesDetails?.map((item, i) => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                key={"payamentRecpt" + i}
                sx={{ p: "10px" }}
              >
                <CounterSalesItemCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default RateChangeItemHistory;
