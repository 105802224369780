import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
// import { getdashBoardApi } from "../../utils/utils";

import { routePath } from "../../constants/routePath";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { getdashBoardApi } from "../../utils/utils";
import { getData2 } from "./dashboardCustomApiHandlers";
export default function Dashboard() {
  const userDetail = useSelector((state) => state.reducer.userDetail);

  const [dashboard, setDashboard] = useState(null);

  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === routePath.DASHBOARD &&
      Object.keys(userDetail).length !== 0
    ) {
      getData2(getdashBoardApi, setDashboard);
    }
  }, [location.pathname, userDetail]);
  // useEffect()
  return (
    <Box>
      {dashboard && (
        <Grid
          container
          sx={{
            overflowY: "auto",
            maxHeight: "calc(100vh - 40px)",

            // p: "50px",
            padding: {
              xs: "10px",
              sm: "50px 10px 10px 10px ",
              md: "50px",
              // Adjust the desired padding value
            },
          }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",

                  backgroundColor: "#2892ba",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Sales
                </Typography>
              </Box>
              <Grid
                container
                sx={{
                  p: "10px",
                  lineHeight: "2.3rem",
                }}
              >
                <Grid item xs={8}>
                  <Box component="span">Today's Total Counter Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCounterSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography component="span">Total Cash Sales:</Typography>
                </Grid>
                <Grid item xs={4} sx={{ color: "green", display: "flex" }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCashSales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Total Credit Sale:</Box>
                </Grid>
                <Grid item xs={4} sx={{ color: "red", display: "flex" }}>
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.totalCreditSales?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",
                  backgroundColor: "#2892ba",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Average Sales:
                </Typography>
              </Box>

              <Grid
                container
                sx={{
                  lineHeight: "2.3rem",
                  p: "10px",
                }}
              >
                <Grid item xs={8}>
                  <Box component="span">Average Daily Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.averageDailySales?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{}}>
                  <Box component="span">Today's Total Difference:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.todaysTotalDifference?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",

                  backgroundColor: "#2892ba",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Weekly Sales:
                </Typography>
              </Box>

              <Grid container sx={{ lineHeight: "2.3rem", p: "10px" }}>
                <Grid item xs={8}>
                  <Box component="span">Current Week Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentWeekSale?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Last Week Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.lastWeekSale?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
                overflow: "hidden", // Add this line
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",
                  backgroundColor: "#2892ba",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Monthly Sales:
                </Typography>
              </Box>

              <Grid
                container
                sx={{ lineHeight: "2.3rem", p: "10px", overflowX: "auto" }}
              >
                <Grid item xs={8}>
                  <Box component="span">Current Month Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.currentMonthSale?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Last Month Sale:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.lastMonthSale?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box
              sx={{
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                minHeight: "30vh",
                overflow: "hidden", // Add this line
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  p: "10px",
                  color: "white",
                  backgroundColor: "#2892ba",
                }}
              >
                <Typography component="span" fontWeight="bold">
                  Expense Information
                </Typography>
              </Box>

              <Grid
                container
                sx={{ lineHeight: "2.3rem", p: "10px", overflowX: "auto" }}
              >
                <Grid item xs={8}>
                  <Box component="span">
                    Todays Expenses:
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "#0da3ca",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.todayExpense?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Current Week Expense:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.weeklyExpense?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Box component="span">Current Month Expanse:</Box>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    color: "red",
                    display: "flex",
                  }}
                >
                  <CurrencyRupeeIcon
                    sx={{
                      fontSize: "19px",
                    }}
                  />
                  <Typography component="span" sx={{ fontWeight: "600" }}>
                    {dashboard?.monthlyExpense?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
