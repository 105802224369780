export const getCounterSales = async (param,outStandingBills,getCounterSalesStatement,setNumberOfRecords,setOutStandingBills) => {
    await getCounterSalesStatement(param)
      .then((res) => {
        setNumberOfRecords(res?.data?.data?.totalRecords);
        console.log("Res OutStanding ", res?.data?.data);
        console.log("Outstanding saltes ", {
          ...outStandingBills,
          ...res?.data?.data,
          records: res?.data?.data?.records,
        });
        setOutStandingBills({
          ...outStandingBills,
          ...res?.data?.data,
          records: res?.data?.data?.records,
        });
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };