import { swalAlertPopProps } from "../../utils/commonUtils";

export const addData = async (param, addExpense, Swal, getPurchase) => {
  await addExpense(param)
    .then((res) => {
      console.log("add ", res);

      if (res?.data?.succeeded) {
        Swal.fire({
          text: "Successfully added record",
          icon: "success",

          ...swalAlertPopProps,
        });
        getPurchase();
      } else {
        Swal.fire({
          text: res?.data?.message,
          icon: "error",

          ...swalAlertPopProps,
        });
      }
    })
    .catch((err) => {
      console.log("err ", err);
    });
};

export const updateBill = async (param, updateExpense, Swal, getPurchase) => {
  await updateExpense(param)
    .then((res) => {
      console.log("update response ", res);

      if (res?.data?.succeeded) {
        Swal.fire({
          text: "Successfully saved record",
          icon: "success",

          ...swalAlertPopProps,
        });
        // getexpence();
        getPurchase();
      } else {
        Swal.fire({
          text: res?.data?.message,
          icon: "error",
          ...swalAlertPopProps,
        });
      }
    })
    .catch((err) => {
      console.log("err ", err);
    });
};
export const getexpence = async (
  param,
  GetDetailsExpense,
  setNumberOfRecords,
  setexpence,
  setTotalAmount
) => {
  await GetDetailsExpense(param)
    .then((res) => {
      console.log("expence ", res.data?.data);
      let arr1 = res?.data?.data?.expenseBills;
      console.log("arr1 ", arr1);
      setNumberOfRecords(res?.data?.data?.totalRecords);
      setexpence(res?.data?.data?.expenseBills);
      setTotalAmount(res?.data?.data?.totalAmount);
    })
    .catch((err) => {
      console.log("err ", err);
    });
};
