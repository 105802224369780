import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";

import { useEffect } from "react";

import { getDiscountedItems, getMonthlyDetails } from "./reportsApi";
import DiscountItemCard from "./discountItemCard";
import { payloadDateFormat } from "../../../utils/commonUtils";

const DiscountinuedItem = () => {
  const [open, setOpen] = useState(false);

  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  const displayDateFormat = () => {
    var currentDate = new Date();
    let dateContainer = String(currentDate).split(" ");

    var year = dateContainer[2];
    var month = dateContainer[1];

    var day = dateContainer[3];

    var formattedDate =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

    return formattedDate;
  };
  const [gstReports, setgstReports] = useState({
    totalSales: "",
    gstReports: [],
  });
  const [discountItem, setDiscountItems] = useState({
    totalItems: "",
    discountItem: [],
  });

  const [billFilter, setBillFilter] = useState("cash");

  const [date, setDate] = useState(displayDateFormat);

  const [startDate, setStartDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const getData = async (param) => {
    await getDiscountedItems(param)
      .then((res) => {
        console.log("discount items ", res?.data);
        setDiscountItems({
          ...discountItem,
          discountItems: res?.data?.discontinuedItemsDetail,
          totalItems: res?.data?.totalItems,
        });
      })
      .catch((err) => {
        console.log("err ", err);
      });
    // await getMonthlyDetails(param).then((res) => {
    //   console.log("Gst Report ", res?.data?.data);
    //   setgstReports({ ...gstReports, gstReports: res?.data?.data });
    // });
  };
  const getData2 = async (param) => {
    await getMonthlyDetails(param).then((res) => {
      console.log("Gst Report ", res?.data?.data);
      // setgstReports({ ...gstReports, gstReports: res?.data?.data });
    });
  };

  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    getData({
      itemDescription: "",
    });
    setDate(formattedDate2);
    setStartDate(date);
  };

  useEffect(() => {
    // getData2({
    //   year: Number(payloadDateFormat().split("-")[0]),
    // });
    console.log("datE ", date);
    getData({
      itemDescription: "",
    });
  }, []);

  return (
    <>
      <Grid
        container
        sx={{
          px: "10px",
        }}
      >
        <DatePicker
          hidden={true}
          selected={startDate}
          maxDate={new Date()}
          onChange={(date) => {
            datePickerOnChange(date);
          }}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => datePickerRef.current.setOpen(false)}
          showYearPicker
        />
        <Grid
          item
          xs={6}
          sx={{
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Total Items:-
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
            {/* {CategorySale?.totalSales} 0 */}
            {discountItem?.totalItems}
          </Box>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={5}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Item/Services
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Rate
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                End Date
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          >
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Month
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Amount
              </Grid>
            </Grid>
          </Grid>
          {discountItem.discountItem.map((item, i) => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={6}
                key={"payamentRecpt" + i}
                sx={{ p: "10px" }}
              >
                <DiscountItemCard item={item} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {/* </Box> */}
      {/* </Drawer> */}
    </>
  );
};

export default DiscountinuedItem;
