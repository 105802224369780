import { ADDBILL, GETCUSTOMERNAMEBYMOBILE } from "../../utils/api";
import axios from "../../utils/axios";

export const addBillCounterSell = async (params) => {
  const res = await axios.post(ADDBILL, params);
  return res;
};

export const customerNameByMobile = async (param) => {
  const res = await axios.post(GETCUSTOMERNAMEBYMOBILE, param);
  return res;
};
