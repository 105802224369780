import { swalAlertPopProps } from "../../utils/commonUtils";

export const getUsers = async (param,getAssociatedUser,setAssociatedUsers) => {
    await getAssociatedUser(param)
      .then((res) => {
        setAssociatedUsers(res.data?.data?.associatedUserDetail);
      })
      .catch((err) => {
        console.error(err);
      });
  };
export   const addUser = async (param,addAssociatedUser,Swal,getUserObj,getAssociatedUser,setAssociatedUsers) => {
    await addAssociatedUser(param)
      .then((res) => {
        if (res.data?.data?.success) {
          Swal.fire({
            text: "Record Saved Successfully ",
            icon: "success", 
            ...swalAlertPopProps,
          });
          getUsers(getUserObj, getAssociatedUser, setAssociatedUsers);
        } else {
          Swal.fire({
            title: res?.data?.message,
            icon: "error", 
            ...swalAlertPopProps,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
 export const updateUser = async (guid, param,updateAssociatedUser,Swal,getUserObj,getAssociatedUser,setAssociatedUsers) => {
    await updateAssociatedUser(guid, param)
      .then((res) => {
        if (res.data?.data?.success) {
          Swal.fire({
            text: "Record Saved Successfully ",
            icon: "success",
          });
          getUsers(getUserObj, getAssociatedUser, setAssociatedUsers);
        } else {
          Swal.fire({
            title: res?.data?.message,
            icon: "error", 
            ...swalAlertPopProps,
          });
        }

        getUsers(getUserObj, getAssociatedUser, setAssociatedUsers);
      })
      .catch((err) => {
        console.error("update err ", err);
      });
  };