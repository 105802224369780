import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";

export default function Loader() {
  const { loading } = useSelector((state) => state.reducer);

  return (
    <div>
      {loading && (
        <Backdrop sx={{ color: "#fff", zIndex: 999999 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </div>
  );
}
