export const pageGridSizeAccordingToLocation = (location, routePath) => {
  return (
    location.pathname !== routePath.LOGIN &&
    location.pathname !== routePath.LANDINGPAGE &&
    location.pathname !== routePath.LANDINGPAGERESTORENT &&
    location.pathname !== routePath.LANDINGPAGECOUNTERTOKEN &&
    location.pathname !== routePath.LANDINGPAGEMONTHLYBILL &&
    location.pathname !== routePath.LANDINGPAGEFRANCHISE &&
    location.pathname !== routePath.COUNTERSELLBILLING &&
    location.pathname !== routePath.UPDATEBILLS
  );
};

export const handleHeaderRenderingAccordingTopages = (routePath, location) => {
  return (
    location.pathname === routePath.LOGIN ||
    location.pathname === routePath.LANDINGPAGE ||
    location.pathname === routePath.LANDINGPAGERESTORENT ||
    location.pathname === routePath.LANDINGPAGECOUNTERTOKEN ||
    location.pathname === routePath.LANDINGPAGEMONTHLYBILL ||
    location.pathname === routePath.LANDINGPAGEFRANCHISE
  );
};

export const handleMobileHeaderRederingAccordingToPages = (
  location,
  routePath
) => {
  return (
    location.pathname !== routePath.LOGIN &&
    location.pathname !== routePath.LANDINGPAGE &&
    location.pathname !== routePath.LANDINGPAGERESTORENT &&
    location.pathname !== routePath.LANDINGPAGECOUNTERTOKEN &&
    location.pathname !== routePath.LANDINGPAGEMONTHLYBILL &&
    location.pathname !== routePath.LANDINGPAGEFRANCHISE &&
    location.pathname !== routePath.COUNTERSELLBILLING &&
    location.pathname !== routePath.UPDATEBILLS
  );
};

export const halfSideBarRenderingAccordingToPages = (location, routePath) => {
  return (
    location.pathname !== routePath.LOGIN &&
    location.pathname !== routePath.LANDINGPAGE &&
    location.pathname !== routePath.LANDINGPAGERESTORENT &&
    location.pathname !== routePath.LANDINGPAGECOUNTERTOKEN &&
    location.pathname !== routePath.LANDINGPAGEMONTHLYBILL &&
    location.pathname !== routePath.LANDINGPAGEFRANCHISE &&
    location.pathname !== routePath.COUNTERSELLBILLING &&
    location.pathname !== routePath.UPDATEBILLS
  );
};

export const footerRenderingAccordingToPages = (location, routePath) => {
  return (
    location.pathname !== routePath.LOGIN &&
    location.pathname !== routePath.LANDINGPAGE &&
    location.pathname !== routePath.LANDINGPAGERESTORENT &&
    location.pathname !== routePath.LANDINGPAGECOUNTERTOKEN &&
    location.pathname !== routePath.LANDINGPAGEMONTHLYBILL &&
    location.pathname !== routePath.LANDINGPAGEFRANCHISE
  );
};

export const ensureAuthenticatedUserOrRedirectToLandingPage = (
  token,
  location,
  routePath
) => {
  return (
    token === null &&
    location.pathname !== "/login" &&
    location.pathname !== routePath.LANDINGPAGERESTORENT &&
    location.pathname !== routePath.LANDINGPAGECOUNTERTOKEN &&
    location.pathname !== routePath.LANDINGPAGEMONTHLYBILL &&
    location.pathname !== routePath.LANDINGPAGEFRANCHISE
  );
};
