import { async } from "@firebase/util";
import store from "../../app/store/store";
import {
  ADDCATEGORIES,
  ADDITEMS,
  GETITEMCATEGORIES,
  GETITEMCATEGORY,
  GETITEMS,
  UPDATECATEGORIES,
  UPDATEITEMS,
} from "../../utils/api";
import axios from "../../utils/axios";

export const getItems = async ({ categoryId }) => {
  const { userDetail } = await store.getState().reducer;

  const res = await axios.post(GETITEMS, {
    myBusinessId: userDetail.myBusinessId,
    itemCategoryId: 0,
    isCounterSalesItem: null,
    page: 1,
    pageSize: 1000,
    filterByColumn: "category",
    filterValue: null,
    searchByColumn: null,
    searchValue: null,
    orderByColumn: null,
    orderBy: null,
  });
  return res;
};

export const addItem = async (payload, isEdit) => {
  const { userDetail } = await store.getState().reducer;
  const res = await axios.post(isEdit ? UPDATEITEMS : ADDITEMS, {
    ...payload,
    myBusinessId: userDetail.myBusinessId,
  });
  return res;
};

export const getItemCategories = async (param) => {
  const res = await axios.post(GETITEMCATEGORIES, param);
  return res;
};

export const addCategories = async (par) => {
  const res = await axios.post(ADDCATEGORIES, par);
  return res;
};

export const updateCategories = async (par) => {
  const res = await axios.post(UPDATECATEGORIES, par);
  return res;
};
