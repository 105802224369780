import { GETMAINTAININVENTORYITEMS, GETSTOCKDETAILS } from "../../utils/api";
import axios from "../../utils/axios";

export const getStockDetail = async (param) => {
  const res = await axios.post(GETSTOCKDETAILS, param);

  return res;
};
export const GetMaintainInventoryItems = async (param) => {
  const res = await axios.post(GETMAINTAININVENTORYITEMS, param);

  return res;
};
