export const creditValidtionCheck = (bill) => {
  console.log("bill ", bill);
  if (bill?.paymentMode === "Credit" && bill?.customerMobile.length !== 10) {
    return { status: false, msg: "Please Enter Customer Mobile" };
  } else if (bill?.paymentMode === "Credit" && bill?.customer.trim() === "") {
    return { status: false, msg: "Please Enter Customer Name " };
  }
  return { status: true, msg: "" };
};

export const checkCartIsEmpty = (arr) => {
  if (arr.length == 0) {
    return {
      status: false,
      msg: "Please add items to cart",
    };
  }
  return {
    status: true,
    msg: "",
  };
};
