import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import { routePath } from "../../constants/routePath";
import { useNavigate } from "react-router";
import logo from "../../assets/headers/logoSMB.png";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useEffect } from "react";
export const HeaderLandingPage = () => {
  const navigate = useNavigate();
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [openDrawer, setOpenDrawer] = useState(false);

  const [headerBtn, setHeaderBtn] = useState([
    {
      key: "Home",
      sectionId: "home",
    },
    {
      key: "About Us",
      sectionId: "about",
    },
    {
      key: "Features",
      sectionId: "features",
    },
    {
      key: "Benefits",
      sectionId: "benefits",
    },
    {
      key: "Contact Us",
      sectionId: "contact",
    },
    {
      key: "Download App",
      sectionId: "",
    },
    {
      key: "Login",
      sectionId: "",
    },
  ]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [selectedSection, setSelectedSection] = useState(0);
  useEffect(() => {
    console.log("setSelectedSection", selectedSection);
  }, [selectedSection]);

  const navItems = (
    <List
      sx={{
        display: "flex",
        color: { xs: "#fff", lg: "#000" },
        flexDirection: { xs: "column", lg: "row" },
        marginRight: "20%",
      }}
    >
      <ListItem
        key="Cancel"
        sx={{ textAlign: "end", display: { xs: "block", lg: "none" } }}
      >
        <CancelOutlinedIcon fontSize="large" onClick={toggleDrawer(false)} />
      </ListItem>
      {headerBtn.map((item, index) => {
        return (
          <ListItem
            button
            key={item.key}
            sx={{
              mx: 0.5,
              borderRadius: "0px",
              backgroundColor:
                selectedSection == index
                  ? (theme) => theme.palette.secondary.main
                  : "inherit",
              "&:hover": {
                backgroundColor:
                  selectedSection == index
                    ? (theme) => theme.palette.secondary.main
                    : (theme) => "#fafafa",
              },
            }}
            onClick={() => {
              let ind = index;
              setSelectedSection(ind);
              navigate(routePath.LANDINGPAGE);
              if (item.sectionId !== "") {
                scrollToSection(item.sectionId);
              } else if (item.key === "Login") {
                navigate(routePath.LOGIN);
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.mybills.mybillsapplication&hl=en_IN&gl=US",
                  "_blank"
                );
              }
            }}
          >
            <ListItemText
              sx={{
                whiteSpace: "nowrap",
                "& .MuiListItemText-primary": { fontSize: "1.2em" },
              }}
              primary={item.key}
            />
          </ListItem>
        );
      })}
    </List>
  );

  const navToggleButton = (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="menu"
      onClick={toggleDrawer(true)}
    >
      <MenuIcon sx={{ color: "#000" }} />
    </IconButton>
  );

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "#fff", boxShadow: "unset", py: 1 }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box component={"img"} src={logo} width={"211px"} />
          {isMobileView ? navToggleButton : navItems}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": { width: "80%", backgroundColor: "#131313" },
        }}
      >
        {navItems}
      </Drawer>
    </>
  );
};
