import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import HelpModal from "./helpSupportModal";
import { useState } from "react";

export default function HelpSupportAccordian({ item, selectedFilterObj }) {
  const [open, setOpen] = useState(false);

  const [modalData, setModalData] = useState({
    id: 0,
    date: "2023-07-29",
    purpose: "string",
    category: "string",
    description: "string",
    loggedBy: "string",
    interimUpdate: "string",
    updateDate: "2023-07-29",
    closureDescription: "string",
    closureDate: "2023-07-29",
    daysElapsed: 0,
    interimStatus: "string",
    closureStatus: "string",
  });
  // const [accordianData, setAccordianData] = useState();
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary"> {item?.question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ pl: "10px" }}>
            <Typography color="secondary">{item?.answer}</Typography>
          </Box>
          <Box mt={"10px"} sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={
                {
                  // mt: "10px",
                }
              }
            >
              Not Satisfied ?{" "}
            </Typography>
            <Typography
              sx={{
                m: "0px 10px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              Write to us
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      <HelpModal
        open={open}
        setOpen={setOpen}
        item={item}
        selectedFilterObj={selectedFilterObj}
      />
    </div>
  );
}
