import {
  ADDASSOCIATEDUSER,
  GETACCOCIATEDUSERSSELECTEDMENU,
  GETASSOCIATEDUSERS,
  SETASSOCIATEDUSER,
  UPDATEASSOCIATEDUSER,
} from "../../utils/api";
import axios from "../../utils/axios";

export const getAssociatedUser = async (param) => {
  const res = await axios.post(GETASSOCIATEDUSERS, param);
  return res;
};
export const addAssociatedUser = async (param) => {
  const res = await axios.post(ADDASSOCIATEDUSER, param);
  return res;
};
export const updateAssociatedUser = async (guid, param) => {
  const res = await axios.put(UPDATEASSOCIATEDUSER + `/${guid}`, param);
  return res;
};


export const getAssociatedUserMenu = async(param) => { 
  const res = await axios.post(GETACCOCIATEDUSERSSELECTEDMENU,param);
  return res;
}

export const setAssociatedUserMenu=async(param)=>{
  const res=await axios.post(SETASSOCIATEDUSER,param);
  return res;
}