export const ENCRYPTIONKEY = "9z8x7c1m2n3b5l4k";
export const LOGIN = "connect/token";
export const api = "user/api/v1/";
export const GETUSER = api + "UserMaster/GetUserDetails";
export const GETMENU = api + "LocalizationOfFunctions/GetBillerHamburgerMenu/";
export const GETMENUHEADER = api + "LocalizationOfFunctions/GetBillerMainMenu/";
export const GETTABLESECTION = api + "Restaurant/GetTableSectionList";
export const GETTABLE = api + "Restaurant/GetTableListV2";
export const ITEMCATEGORY = api + "ItemCategory/GetItemCategories";
export const ITEMGET = api + "ItemsMaster/GetItems";
export const USERPROFILE = api + "UserMaster/GetUserProfile";
export const UPDATEUSERPROFILE = api + "UserMaster/UpdateUserProfile";
export const USERPROFILEIMG = api + "UserMaster/GetUserProfile";
export const UPDATEUSERPROFILIMAGE = api + "UserMaster/ChangeProfilePicBase64";
export const GETBUSSINESSDATA = api + "MyBusiness/GetActiveBusiness";
// export const "http://103.13.114.194:4080/webapp/user/api/v1/"
export const UPDATEBUSSINESSDATA = api + "MyBusiness/Update";
export const ADDBILL = api + "CounterSales/Add";

export const GETBILL = api + "CounterSales/GetAllBills";
export const UPDATEBILL = api + "CounterSales/Update";
export const ADDTABLE = api + "Restaurant/AddTable";
export const GETDASHBOARDDATE = api + "ShowMyBills/GetBillerDashboardDetails";
export const GETMASTERTABLE = api + "Restaurant/GetTableList";

export const GETITEMS = api + "ItemsMaster/GetItems";
export const ADDITEMS = api + "ItemsMaster/Add";
export const UPDATEITEMS = api + "ItemsMaster/UpdateItem";
export const ADDCATEGORIES = api + "ItemCategory/Add";
export const UPDATECATEGORIES = api + "ItemCategory/Update";

export const GETTABLESECTIONS = api + "Restaurant/GetTableSectionList";
export const GETNOTASSIGNTABLELIST = api + "Restaurant/GetNotAsssignTableList";
export const UPDATETABLESECTION = api + "Restaurant/UpdateTableSection";
export const ADDTABLESECTION = api + "Restaurant/AddTableSection";
export const GETITEMCATEGORIES = api + "ItemCategory/GetItemCategories";
export const UPDATETABLEMASTER = api + "Restaurant/UpdateTable";
export const GETASSOCIATEDUSERS = api + "AssociatedUser/GetAssociatedUsers";
export const ADDASSOCIATEDUSER = api + "AssociatedUser/AddAssociateUser";
export const UPDATEASSOCIATEDUSER = api + "AssociatedUser/UpdateAssociateUser";
export const GETACCOCIATEDUSERSSELECTEDMENU =
  api + "AssociatedUser/GetAssociatedUsersSelectedMenu";
export const SETASSOCIATEDUSER =
  api + "AssociatedUser/SetAssociatedUsersSelectedMenu";
export const GETUNPAIDBILLS = api + "CounterSales/GetUnpaidBillsByBuyer";
export const GETPAYMENTRECEIPT = api + "CounterSales/GetPaymentReceipts";
export const GETCOUNTERSALESCUSTOMERS =
  api + "CounterSales/GetCouterSalesCustomers";
export const ADDCASHPAYMENT = api + "CounterSales/AddCashPayment";
export const ADDBILLCASHRECEIPT = api + "PaymentReceipt/AddBillerCashReceipt";
export const GETPAYMENTXLSX = api + "CounterSales/GetPaymentReceiptsXlsx";
export const GETCOUNTERSELAESSTATEMENT =
  api + "Reports/ReportOutstandingCouterSalesStatement";
export const GETACCOUNTRECHAGEDETAILS =
  api + "BuyersInfo/GetAccountRechargeDetails";
export const GETRENEWSUBSCRIPTIONDETAIL =
  api + "BuyersInfo/GetRenewSubscriptionDetails";
export const GETCATEGORYWISECOUNTERSALE =
  api + "CounterSales/GetCategoryWiseCounterSale";
export const GETMONTLYGSTDETAILS = api + "CounterSales/GetMontlyGSTDetails";
export const GETDISCOUNTEDITEMS = api + "ItemsMaster/GetDiscontinuedItems";
export const GETOVERALLCOUNTERDETAILS =
  api + "CounterSales/GetOverAllCounterSalesDetails";
export const GETCOUNTERSALESREPORT = api + "CounterSales/GetCounterSalesReport";
export const GETSTOCKDETAILS = api + "Purchse/GetStockDetails";
export const GETMAINTAININVENTORYITEMS =api+"ItemsMaster/GetMaintainInventoryItems"
export const GETPURCHASEDETAILS = api + "Purchse/GetDetails";
export const PURCHASEADD = api + "Purchse/Add";

export const GETDETAILSEXPENSE = api + "Expense/GetDetails";
export const ADDEXPENSE = api + "Expense/Add";
export const GETDETALS = api + "Expense/GetDetailsXlsx";
export const UPDATEEXPENSE = api + "Expense/Update";
export const GELLALLCATEGORIESHELPSUPPORT =
  api + "FAQCategory/GetAllCategories";
export const GETALLUSERFAQ = api + "FAQs/GetAllUserFAQsByLanguage";
export const ADDBILLERSERICE = api + "ServiceRequest/AddBillerServiceRequest";
// export const ADDB"ServiceRequest/AddBillerServiceRequest"
export const UPDATEPIN = api + "UserMaster/SetMPin";

export const PRINTERSETTING = api + "MyBusiness/UpdatePrinterSettings";

export const GETCUSTOMERNAMEBYMOBILE =
  api + "CounterSales/GetCustomerNameByMobile";

export const GETRATECHANGEHISTORY = api + "ItemsMaster/GetRateChangeHistory";
export const GETCOUNTERSALESSUMMARY =
  api + "CounterSales/GetCounterSalesSummary";

export const GETKYCDOCUMENTS = api + "KYCDocument/GetKYCDocuments";
export const UPLOADDOCUMENTS = api + "KYCDocument/UploadKYCDocumentBase64";
