import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Pagination,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";

import DatePicker from "react-datepicker";
import EventIcon from "@mui/icons-material/Event";
import SearchIcon from "@mui/icons-material/Search";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import UserCard from "./userCard";
import Swal from "sweetalert2";
import { GetMaintainInventoryItems, getStockDetail } from "../stocks/stockApi";

import PurchaseItemCard from "./purchaseItemCard";
import { convertDateFormat, swalAlertPopProps } from "../../utils/commonUtils";
import { postPurchaseDetail } from "./purchaseApi";
import { validationAddPurchase } from "./purchaseValidationFunction";
import { totalPrice } from "./purFunction";

export default function PuchaseModal({ open, setOpen, getPurchase, searchItem, setSearchItem, purchaseItem, setPurchaseItem, payload, search, setSearch, openSearch, setOpenSearch }) {
  //   const [pagination, setPagination] = useState({ page: 1, total: 10 });
  // const [search, setSearch] = useState("");

  const data = {
    customerMobile: "",
  };
  const [billData, setBillData] = useState(data);

  const onClose = () => {
    setOpen(false);
  };
  const handleChange = (event, value) => {
    setPagination({
      ...pagination,
      page: value,
    });
  };

  // const [searchItem, setSearchItem] = useState([]);
  const getStocks = async (param) => {
    await GetMaintainInventoryItems(param)
      .then((res) => {
        console.log("purhcse element ", res.data.itemsDetails);
        setPagination({
          ...pagination,
          total: res?.data?.totalPages,
          records: res?.data?.totalRecords,
        });
        setSearchItem(res?.data?.itemsDetails);
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };

  const stockPayload = {
    page: 1,
    pageSize: 1000000,
  };

  const [purchaseItemsArr, setPurchaseItemsArr] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 10,
    records: 0,
  });

  useEffect(() => {
    if (open === true) {
      getStocks(stockPayload);
    } else {
      console.log("close");
      setPurchaseItem(payload);
      setPurchaseItemsArr([]);
    }
  }, [open]);
  const addLeadingZero = (value) => {
    return value < 10 ? "0" + value : value;
  };
  const [date, setDate] = useState(
    (() => {
      var currentDate = new Date();
      let dateContainer = String(currentDate).split(" ");
      var year = dateContainer[2];
      var month = dateContainer[1];
      var day = dateContainer[3];
      var formattedDate =
        year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

      return formattedDate;
    })()
  );

  const [startDate, setStartDate] = useState(new Date());
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };
  const addData = async (param) => {
    await postPurchaseDetail(param)
      .then((res) => {
        console.log("add ", res);

        if (res?.data?.succeeded) {
          Swal.fire({
            text: "Successfully added record",
            icon: "success",

            ...swalAlertPopProps,
          });
          getPurchase();
        } else {
          Swal.fire({
            text: res?.data?.message,
            icon: "error",

            ...swalAlertPopProps,
          });
        }
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
  // const [status, setStatus] = useState("all");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "52%",
          overflowY: "auto",
          // height: "90vh",
          position: "absolute",
          left: "25%",
          top: "10%",
        }}
      >
        <DialogTitle
          color="primary"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 'auto',
            p: "0px 10px !important"
          }}
        >
          Purchase{" "}
          <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
        </DialogTitle>
        <Divider />
        <Grid container sx={{ position: "relative" }}>

          <Grid item xs={6} sx={{ p: 0 }}  >
            <DialogContent sx={{ pr: 0 }} >

              <Box sx={{ cursor: "pointer" }}>
                <TextField
                  fullWidth
                  // disabled
                  readonly
                  onClick={openDatePicker}
                  id="date"
                  value={date}
                  label={
                    <Box
                      sx={{
                        flex: 4,
                        color: "grey",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Date : {/* {new Date().toLocaleDateString()} */}
                    </Box>
                  }
                  onChange={(e) => { }}
                  InputProps={{
                    style: {
                      height: '40px',
                      // background:"yellow"
                    },
                    endAdornment: (
                      <EventIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                          cursor: "pointer",
                        }}
                      />
                    ),
                  }}
                />
                <DatePicker
                  hidden={true}
                  selected={startDate}
                  maxDate={new Date()}
                  onChange={(date) => {
                    const dateStr2 = date.toLocaleDateString("en-GB", {
                      year: "numeric",
                      month: "long",
                      day: "2-digit",
                    });
                    let dateContainer = String(dateStr2).split(" ");
                    var year2 = dateContainer[2];
                    var month2 = dateContainer[1];
                    var day2 = dateContainer[0];
                    var formattedDate2 = day2 + "-" + month2 + "-" + year2;

                    setDate(formattedDate2);
                    setStartDate(date);
                  }}
                  className="custom-datepicker"
                  ref={datePickerRef}
                  onClickOutside={() => datePickerRef.current.setOpen(false)}
                />
              </Box>

              <Box sx={{ mt: 2 }}>
                {/* <TextField
                  fullWidth
                  size="small"
                  id="name"

                  label="Selected Item"
                  value={purchaseItem?.description}
                  onClick={() => {
                    setOpenSearch(true)
                  }}
                  InputProps={{
                    endAdornment: (
                      <SearchIcon
                        onClick={() => {
                          setOpenSearch(true)
                        }}
                        sx={{
                          // height: "40px",
                          cursor: "pointer",
                          color: (theme) => theme.palette.primary.main,
                        }}
                      />
                    ),
                  }}
                /> */}
                <Box
                  onClick={() => {
                    setOpenSearch(true)

                  }}
                  sx={{
                    display: "flex",
                    height: "23px",
                    justifyContent: "space-between",
                    border: "1px solid #c9c9c9",
                    p: 1,
                    borderRadius: "4px ",
                    color: "grey",
                    '&:hover': {
                      cursor: 'pointer !important',
                    },
                  }}
                >
                  <Typography variant="p">
                    {purchaseItem?.description !== "" ? purchaseItem?.description : "Selected Item"}
                  </Typography>
                  <SearchIcon
                    onClick={() => {
                      setOpenSearch(true)

                    }}
                    sx={{
                      color: (theme) => theme.palette.primary.main,
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  id="Quantity"
                  type="number"
                  size="small"
                  label="Quantity"
                  value={purchaseItem?.quantity !== 0 ? purchaseItem?.quantity : ""}
                  onChange={(e) => {
                    setPurchaseItem({
                      ...purchaseItem,
                      quantity: e.target.value > 0 ? Number(e.target.value) : "",
                    });
                  }}
                />

              </Box>
              <Box sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  id="briefAddress"
                  type="number"
                  size="small"
                  label="Amount"
                  value={purchaseItem?.price !== 0 ? purchaseItem?.price : ""}
                  onChange={(e) => {
                    setPurchaseItem({
                      ...purchaseItem,
                      price: e.target.value > 0 ? Number(e.target.value) : "",
                    });
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setPurchaseItemsArr([]);
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      const valid = validationAddPurchase(purchaseItem);
                      if (valid?.status) {
                        setPurchaseItemsArr([
                          ...purchaseItemsArr,
                          {
                            ...purchaseItem,
                            amount: purchaseItem.price * purchaseItem.quantity,
                          },
                        ]);
                        console.log(convertDateFormat(date));
                        setPurchaseItem(payload);
                      } else {
                        Swal.fire({
                          text: valid.msg,
                          icon: "warning",

                          ...swalAlertPopProps,
                        });
                        setOpen(false);
                      }
                    }}
                  >
                    Add
                  </Button>
                </DialogActions>
              </Box>
            </DialogContent>
          </Grid>
          <Grid item xs={0.5} sx={{ display: "flex", justifyContent: "center" }}>
            <Box style={{ borderRight: '1px solid #ccc', height: '100%' }} />
          </Grid>
          <Grid item xs={5.5} sx={{ pr: 2 }}>
            <Box
              sx={{
                height: "30vh",
                overflowY: "auto",

              }}
            >
              {purchaseItemsArr?.map((item, index) => {
                return (
                  <PurchaseItemCard
                    key={index + "purchaseitem"}
                    index={index}
                    item={item}
                    arr={purchaseItemsArr}
                    setPurchaseItemsArr={setPurchaseItemsArr}
                  />
                );
              })}
            </Box>
            <Divider
              sx={{
                background: (theme) => theme.palette.primary.main,
                width: "100%",
                mt: 1,
              }}
            />
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-around'
            }}>
              <Typography color="primary">
                <strong>Total Amount: &nbsp;
                </strong>
              </Typography>
              <Box
                component="span"
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                }}
              >
                {" "}
                <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />
                {
                  totalPrice(purchaseItemsArr)}
              </Box>
            </Box>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                setOpen(false);
                console.log("bill Data ", billData);
                if (purchaseItemsArr.length === 0) {
                  Swal.fire({
                    text: "Please Add Product",
                    icon: "warning",

                    ...swalAlertPopProps,
                  });
                } else {
                  addData({
                    date: convertDateFormat(date),
                    purchaseDetails: purchaseItemsArr,
                  });
                }

                console.log("billData ", {
                  customer: billData?.customer,
                  customerMobile: billData?.customerMobile,
                  amount: parseInt(billData?.amount),
                  isCounterSalesBill: true,
                  paymentMode: billData?.paymentMode,
                });
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal >
  );
}
