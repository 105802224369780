
// import {    GETCOUNTERSALESREPORT, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS } from " ../../utils/api";
import { GETCOUNTERSALESREPORT, GETMONTLYGSTDETAILS, GETOVERALLCOUNTERDETAILS, GETPURCHASEDETAILS, PURCHASEADD } from "../../utils/api";
import axios from "../../utils/axios";
 
// export const getCategorySalesCustomers=async(param)=>{
//     const res=await axios.post(GETCATEGORYWISECOUNTERSALE,param);
//     return res;
// }
export const getMonthlyDetails=async(param)=>{
    const res=await axios.post(GETMONTLYGSTDETAILS,param);
    return res;
}

export const getOverallCounterDetail=async(param)=>{
    const res=await axios.post(GETOVERALLCOUNTERDETAILS,param)
    return res;
}
export const getCounterSalesReport=async(param)=>{
    const res = await axios.post(GETCOUNTERSALESREPORT,param);
    return res;
}
export const getPurchaseDetail=async(param)=>{
    const res=await axios.post(GETPURCHASEDETAILS,param)
    return res;
}
export const postPurchaseDetail=async(param)=>{
    const res=await axios.post(PURCHASEADD,param)
    return res;
}
