import * as React from "react";
import Box from "@mui/material/Box";

import ReceiptIcon from "@mui/icons-material/Receipt";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import logo from "../assets/headers/logoSMB.png";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Fab, Menu } from "@mui/material";
import { routePath } from "../constants/routePath";
import { setDrawerShow, setMobileTableDrawer } from "../app/store/reducer";
export default function MobileHeader({ setDrawerShow }) {
  const location = useLocation();

  const headerMenu = useSelector((state) => state.reducer.headerMenu);
  const urlHeaderMenu = useSelector((state) => state.reducer.urlHeaderMenu);
  const drawerShow = useSelector((state) => state.reducer.drawerShow);

  const [currentPage, setCurrentPage] = React.useState(-1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const indexRoute3 = urlHeaderMenu.findIndex(
      (it) => it.route === location.pathname
    );

    setCurrentPage(indexRoute3);
  }, [location.pathname]);
  const style = {
    background: "white",
    position: "relative",
    flexGrow: 1,
    borderBottom: "1px solid #edebed",
  };
  return (
    <Box sx={style}>
      <Grid container spacing={0} sx={{ p: "0px 0px", height: "60px" }}>
        <Grid
          item
          xs={12}
          // xs={2}
          sx={{
            borderRight: "0.2px solid #e5e5e5",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",

            cursor: "pointer",
            height: "60px",
          }}
          onClick={() => {
            // if (location.pathname !== routePath.LOGIN) navigate("/");
          }}
        >
          <img src={logo} alt="logo" style={{ height: "50px" }} />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <MenuIcon onClick={() => setDrawerShow(true)} />
            {location.pathname === "/countersell" && (
              <React.Fragment>
                <Fab
                  sx={{ marginLeft: "10px" }}
                  color="primary"
                  aria-label="Add to Cart"
                  size="small"
                  // sx={{ float: "right" }}
                  onClick={() => dispatch(setMobileTableDrawer(true))}
                >
                  <ReceiptIcon />
                </Fab>
              </React.Fragment>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
