import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Divider,
  TextField,
  Modal,
  Grid,
} from "@mui/material";
import DialpadIcon from "@mui/icons-material/Dialpad";
import React from "react";
import {
  ApplyAccordingREAndCBUser,
  setQtyCbUser,
} from "./counterSellBillFunctions";
import { useSelector } from "react-redux";

export default function QuantityModal({
  open,
  setOpen,
  modalQuantity,
  setModalQuantity,
  sendValue,
  fcmRowId,
  addtoCardProductCBUser,
  setAddtoCardProductCBUser,
}) {
  const userDetail = useSelector((state) => state.reducer.userDetail);
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "40%",
          position: "absolute",
          left: "32%",
          top: "20%",
        }}
      >
        <DialogTitle color="primary" fontWeight="bold">
          Quantity
        </DialogTitle>

        <Divider />
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              p: "20px",
              // display: "flex",

              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {Array.from({ length: 20 }, (__i, index) => {
              return (
                <Box
                  key={"quantityModal" + index}
                  sx={{
                    marginRight: " 10px",
                    mb: "10px",
                    cursor: "pointer",
                    display: "flex",

                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    width: "100px",
                    p: "0px",
                    fontWeight: "bold",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    // index
                    //
                    setModalQuantity({
                      ...modalQuantity,
                      qty: Number(index + 1),
                    });

                    setOpen(false);
                    ApplyAccordingREAndCBUser(
                      userDetail,
                      () => {
                        return sendValue(
                          { ...modalQuantity, qty: Number(index + 1) },
                          fcmRowId
                        );
                      },
                      () => {
                        return setQtyCbUser(
                          modalQuantity?.elementIndex,
                          addtoCardProductCBUser,
                          setAddtoCardProductCBUser,
                          Number(index + 1)
                        );
                      }
                    );
                  }}
                >
                  {index + 1}
                </Box>
              );
            })}
          </Grid>
        </Grid>
        <Grid container sx={{ position: "relative" }}>
          <Grid
            item
            xs={12}
            sx={{
              p: "20px",
            }}
          >
            <TextField
              sx={{
                background: "white",
                float: "left",
                borderRadius: "5px",
                width: "100%",
              }}
              value={modalQuantity.qty}
              variant="outlined"
              placeholder="Search in Menu"
              onChange={(e) => {
                setModalQuantity({
                  ...modalQuantity,
                  qty: Number(e.target.value),
                });
              }}
              InputProps={{
                endAdornment: <DialpadIcon />,
              }}
            />
          </Grid>
        </Grid>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              // sendValue(modalQuantity, fcmRowId);
              ApplyAccordingREAndCBUser(
                userDetail,
                () => {
                  return sendValue(modalQuantity, fcmRowId);
                },
                () => {
                  return setQtyCbUser(
                    modalQuantity?.elementIndex,
                    addtoCardProductCBUser,
                    setAddtoCardProductCBUser,
                    modalQuantity?.qty
                  );
                }
              );
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Box>
    </Modal>
  );
}
