import { GETACCOUNTRECHAGEDETAILS, GETRENEWSUBSCRIPTIONDETAIL } from "../../utils/api"
import axios from "../../utils/axios"

export const getAccountReachageDetails=async()=>{
    const res=await axios.get(GETACCOUNTRECHAGEDETAILS)
    return res;
}
export const getNewSubscriptionReachageDetails=async()=>{
    const res=await axios.get(GETRENEWSUBSCRIPTIONDETAIL)
    return res;
}

// GETRENEWSUBSCRIPTIONDETAIL