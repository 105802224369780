import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import landingSvg from "../../assets/landingSvg.png";
import Aboutbg from "../../assets/aboutbg.png";
import AboutVector from "../../assets/about.png";
import FeatureBg from "../../assets/featurebg.svg";
import FeatureVector from "../../assets/feature.png";
import POS from "../../assets/icon/pos.png";
import Tablet from "../../assets/icon/tablet.png";
import Restaurant from "../../assets/icon/restaurant.png";
import Outlet from "../../assets/icon/outliet.png";
import Calendar from "../../assets/icon/calendar.png";
import Token from "../../assets/icon/token.png";
import Printer from "../../assets/icon/printer.png";
import { keyframes } from "@emotion/react";
import { Footer } from "./footer";
import { useMediaQuery } from "@mui/material";

// import { Header } from "./header";
import { RestaurantPage } from "./restaurantPage";
import { HeaderLandingPage } from "./header";
import { routePath } from "../../constants/routePath";
const LandingPage = () => {
  const navigate = useNavigate();

  const bounceAnimation = keyframes`
  0%{
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
`;
  const isMobileView = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const animationStyles = {
    width: "90%",
  };

  if (!isMobileView) {
    // Apply animation styles for non-mobile views
    Object.assign(animationStyles, {
      animation: `${bounceAnimation} 2s infinite`,
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      animationDuration: "2s",
      animationDelay: "0",
      animationFillMode: "forwards",
      animationPlayState: "running",
    });
  }

  return (
    <Box sx={{ backgroundColor: "#fafafa" }}>
      {/* <Header/> */}
      <HeaderLandingPage />
      <Container id="home">
        <Grid container pt={10} alignItems={"center"}>
          <Grid item md={6}>
            <Typography
              variant="h2"
              sx={{ fontWeight: "600", color: "#e8410b" }}
            >
              SHOW MY BILLS
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold", fontFamily: "Be Vietnam Pro" }}>
              Billing Made Easy, Anytime, Anywhere
            </Typography>
            <Typography
              sx={{
                fontFamily: "Be Vietnam Pro",
                mt: 1,
                paddingRight: { sm: '0px', md: "100px" },
              }}
            >
              <span style={{ color: "#e8410b" }}>Show My Bills</span> is an
              Android Based Billing application and a Business Optimized
              solution, it is very useful for various types of businesses,
              service providers & shop owners. It can automate the billing
              process, generate reports, help businesses as per their
              requirements, enhance customer experience, and increase
              productivity.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Be Vietnam Pro",
                mt: 1,
                paddingRight: { sm: '0px', md: "100px" },
              }}
            >
              <span style={{ color: "#e8410b" }}>Show My Bills</span> is not
              only limited to Billing Applications or POS but also helps
              businesses to track their day-to-day activities. It is a Business
              Optimized solution and is considered an ERP for micro, small, and
              medium businesses to support them to reduce their repetitive tasks
              to manage their business in a better way and it primarily helps
              the following business types:
            </Typography>
          </Grid>
          <Grid item md={6} textAlign={'center'} sx={{ paddingTop: { sm: '50px', md: '0px' } }}>
            <Box
              component={"img"}
              sx={{ ...animationStyles }}
              src={landingSvg}
            />
          </Grid>
        </Grid>
        <Box sx={{ transform: { sm: 'translateY(0px)', md: "translateY(80px)" } }}>
          <Grid container justifyContent={'center'}>
            <Grid item md={3} sm={6} xs={12} display={'flex'} justifyContent={'center'} sx={{ padding: { sm: '10px 0px', md: '0px 0px' } }}>
              <Box
                display={"flex"}
                padding={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                sx={{
                  boxShadow: "5px 5px 17px #000",
                  minHeight: "260px",
                  maxWidth: "200px",
                  borderRadius: "15px",
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                }}

                onClick={() => navigate(routePath.LANDINGPAGERESTORENT)}
              >
                <Box
                  component={"img"}
                  src={Restaurant}
                  alt="icon"
                  height={"130px"}
                />
                <Typography
                  fontSize={"27px"}
                  mt={2}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  RESTAURANT
                </Typography>
              </Box>
            </Grid>

            <Grid item md={3} sm={6} xs={12} display={'flex'} justifyContent={'center'} sx={{ padding: '10px 0px' }}>
              <Box
                display={"flex"}
                padding={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                sx={{
                  boxShadow: "5px 5px 17px #000",
                  minHeight: "260px",
                  maxWidth: "200px",
                  borderRadius: "15px",
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                }}
                onClick={() => navigate(routePath.LANDINGPAGECOUNTERTOKEN)}
              >
                <Box
                  component={"img"}
                  src={Token}
                  alt="icon"
                  height={"130px"}
                />
                <Typography
                  fontSize={"24px"}
                  mt={2}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  COUNTER TOKEN SYSTEM
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} sm={6} xs={12} display={'flex'} justifyContent={'center'} sx={{ padding: '10px 0px' }}>
              <Box
                display={"flex"}
                padding={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                sx={{
                  boxShadow: "5px 5px 17px #000",
                  minHeight: "260px",
                  maxWidth: "200px",
                  borderRadius: "15px",
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                }}
                onClick={() => navigate(routePath.LANDINGPAGEMONTHLYBILL)}
              >
                <Box
                  component={"img"}
                  src={Calendar}
                  alt="icon"
                  height={"130px"}
                />
                <Typography
                  fontSize={"24px"}
                  mt={2}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  MONTHLY BILLING
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} sm={6} xs={12} display={'flex'} justifyContent={'center'} sx={{ padding: '10px 0px' }}>
              <Box
                display={"flex"}
                padding={"16px"}
                justifyContent={"center"}
                alignItems={"center"}
                flexDirection={"column"}
                sx={{
                  boxShadow: "5px 5px 17px #000",
                  minHeight: "260px",
                  maxWidth: "200px",
                  borderRadius: "15px",
                  backgroundColor: "#fafafa",
                  cursor: "pointer",
                }}
                onClick={() => navigate(routePath.LANDINGPAGEFRANCHISE)}
              >
                <Box
                  component={"img"}
                  src={Outlet}
                  alt="icon"
                  height={"130px"}
                />
                <Typography
                  fontSize={"24px"}
                  mt={2}
                  fontWeight={"bold"}
                  textAlign={"center"}
                >
                  FRANCHISE OUTLET
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Box sx={{ backgroundImage: `url(${Aboutbg})` }} id="about">
        <Container>
          <Grid
            container
            alignItems={"center"}
            sx={{ height: { sm: "auto", md: "750px" } }}
            padding={"100px 0px"}
          >
            <Grid item md={6}>
              <Typography
                variant="h2"
                sx={{ fontWeight: "600", color: "#e8410b" }}
              >
                About Us
              </Typography>
              <Typography>
                Initially <Typography component={"span"} color="secondary">Show My Bills</Typography> was started to provide a solution for
                monthly recurring bills and payments to small vendors during the
                Pandemic. While conducting a survey, we came to know many
                businesses operational challenges of the vendors and that their
                functioning was solely dependent on the individuals. Thus, we
                came up with <Typography component={"span"} color="secondary">Show My Bills</Typography> application and tried to automate all
                tasks through simple record-keeping. We were successful as this
                application helps overcome all the challenges and digitalizes
                small businesses.
              </Typography>
              <Typography>
                There are many applications in the market, but these
                applications are not affordable, need expensive hardware, and
                lack functionalities for small vendors, restaurants, and shop
                owners.
              </Typography>
              <Typography>
                <Typography component={"span"} color="secondary">Show My Bills</Typography> Application is upgrading every day with technical
                and business functionalities. Our goal is to understand the
                business needs of the common man and provide solutions with
                innovation, and simple user interfaces.
              </Typography>
              <Typography>
                We are committed to provide 24*7 service to our customers.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                component={"img"}
                src={AboutVector}
                alt="vector"
                sx={{ ...animationStyles }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ backgroundImage: `url(${FeatureBg})`, padding: { sm: '50px 0px', md: '0px 0px' } }} id="features">
        <Container>
          <Grid container justifyContent={'center'} alignItems={"center"} sx={{ height: { sm: "auto", md: "750px" } }}>
            <Grid item md={6}>
              <Box
                component={"img"}
                src={FeatureVector}
                alt="vector"
                sx={{ ...animationStyles }}
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "500", color: "#2d3c58" }}
              >
                Key features of the Show My Bills App
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Transparent day-to-day Record Keeping.
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Quick and secure UPI-integrated Payment Portal.
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Automated distribution charts.
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Multiple format access of billing and transactional records.
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Quick and easy calendar updates
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Alerts & Notification broadcast function.
              </Typography>
              <Typography sx={{ margin: "20px 10px 10px" }}>
                Easy coordination between Service Providers and Customers.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: "#25262d" }}>
        <Container>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              height: { sm: '200px', xs: 'auto' },
              paddingTop: {
                xs: "",
                sm: "50px"
              }
            }}
          >
            <Grid
              item
              xs={12} sm={4}
              sx={{
                margin:
                  { xs: '35px 0px', sm: '0px' },


              }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box textAlign={"center"}>
                <Typography variant="h5" color={"#00c6c0"} fontSize={"48px"}>
                  4.5
                </Typography>
                <Typography color={"#00c6c0"} fontSize={"17px"}>
                  HIGH RATINGS
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12} sm={4}
              sx={{ margin: { xs: '35px 0px', sm: '0px' } }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box textAlign={"center"}>
                <Typography variant="h5" color={"#00c6c0"} fontSize={"48px"}>
                  2500+
                </Typography>
                <Typography color={"#00c6c0"} fontSize={"17px"}>
                  DOWNLOADS
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12} sm={4}
              sx={{ margin: { xs: '35px 0px', sm: '0px' } }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box textAlign={"center"}>
                <Typography variant="h5" color={"#00c6c0"} fontSize={"48px"}>
                  750+
                </Typography>
                <Typography color={"#00c6c0"} fontSize={"17px"}>
                  ACTIVE USERS
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: "#E6F7E2" }} py={10}>
        <Container>
          <Typography
            textAlign={"center"}
            pb={10}
            variant="h2"
            sx={{ fontWeight: "600", color: "#e8410b" }}
          >
            OUR BEST OFFERS
          </Typography>
          <Grid container justifyContent={'center'} sx={{ flexDirection: { sm: 'column', md: 'row' } }}>
            <Grid item md={4} display={"flex"} justifyContent={"center"} my={1.5}>
              <Box
                sx={{
                  width: "16em",
                  height: "20em",
                  borderRadius: "3em",
                  fontSize: "14px",
                  border: "2px solid #6F6FFF",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100px",
                    position: "relative",
                    left: "20%",
                    top: "-24%",
                    borderRadius: "50%",
                  }}
                >
                  <Box width={"10em"} component={"img"} src={Printer} />
                </Box>
                <Box
                  textAlign={"center"}
                  sx={{
                    position: "absolute",
                    top: "31%",
                    left: "0",
                    right: "0",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    PRINTER{" "}
                  </Typography>
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                    sx={{
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        left: "33%",
                        top: "43%",
                        right: "32%",
                        borderTop: "2px solid #E8410B",
                        transform: "rotate(-5deg)",
                      },
                    }}
                  >
                    {" "}
                    ₹9,000{" "}
                  </Typography>
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    ₹ 6,999{" "}
                  </Typography>
                  <Typography fontSize={"14px"}>
                    (Printer + Software){" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} display={"flex"} justifyContent={"center"} my={1.5}>
              <Box
                sx={{
                  width: "16em",
                  height: "20em",
                  borderRadius: "3em",
                  fontSize: "14px",
                  border: "2px solid #6F6FFF",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100px",
                    position: "relative",
                    left: "20%",
                    top: "-24%",
                    borderRadius: "50%",
                  }}
                >
                  <Box width={"10em"} component={"img"} src={Tablet} />
                </Box>
                <Box
                  textAlign={"center"}
                  sx={{
                    position: "absolute",
                    top: "31%",
                    left: "0",
                    right: "0",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    TABLET{" "}
                  </Typography>
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                    sx={{
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        left: "33%",
                        top: "43%",
                        right: "32%",
                        borderTop: "2px solid #E8410B",
                        transform: "rotate(-5deg)",
                      },
                    }}
                  >
                    {" "}
                    ₹18,000{" "}
                  </Typography>
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    ₹ 15,999{" "}
                  </Typography>
                  <Typography fontSize={"14px"}>
                    (TAB + Printer + Software){" "}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} display={"flex"} justifyContent={"center"} my={1.5}>
              <Box
                sx={{
                  width: "16em",
                  height: "20em",
                  borderRadius: "3em",
                  fontSize: "14px",
                  border: "2px solid #6F6FFF",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100px",
                    position: "relative",
                    left: "20%",
                    top: "-24%",
                    borderRadius: "50%",
                  }}
                >
                  <Box width={"10em"} component={"img"} src={POS} />
                </Box>
                <Box
                  textAlign={"center"}
                  sx={{
                    position: "absolute",
                    top: "31%",
                    left: "0",
                    right: "0",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    P . O . S{" "}
                  </Typography>
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                    sx={{
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        left: "33%",
                        top: "43%",
                        right: "32%",
                        borderTop: "2px solid #E8410B",
                        transform: "rotate(-5deg)",
                      },
                    }}
                  >
                    {" "}
                    ₹19,000
                  </Typography>
                  <Typography
                    fontSize={"24px"}
                    lineHeight={"2em"}
                    fontWeight={"bold"}
                  >
                    {" "}
                    ₹ 16,999{" "}
                  </Typography>
                  <Typography fontSize={"14px"}>
                    (P.O.S Machine + Software)
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box py={10} id="benefits">
        <Container>
          <Typography
            textAlign={"center"}
            variant="h2"
            sx={{ fontWeight: "600", color: "#e8410b" }}
          >
            Benefits
          </Typography>
          <Typography
            py={3}
            fontFamily={"Be Vietnam Pro"}
            fontSize={"20px"}
            fontWeight={"600"}
          >
            Here are some benefits of using the Application
          </Typography>
          <Grid container>
            <Grid item md={6} sx={{ padding: { sm: '20px 0px', md: '0px 20px' } }}>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Automates Billing Process
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  With an over-the-counter sale feature, customers can easily
                  make purchases online and then pick up their items at a
                  physical store location. This can save time and hassle
                  compared to traditional shopping methods.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Increases Productivity
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Businesses can free up their staff's time from administrative
                  tasks such as billing and invoicing. This can allow them to
                  focus on other important areas of the business, such as
                  customer service or product development.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Enhances Customer Experience
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  This provides customers with a convenient and user-friendly
                  platform to manage their payments. Customers can view their
                  invoices, payment history, and upcoming payments, and make
                  payments online, making the process more efficient and
                  satisfying.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Transparency
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  It brings about transparency in the relationship between
                  suppliers/service providers and their customers or
                  stakeholders during each billing cycle.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Record Keeping
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Recording transaction is nothing but record keeping. It is
                  very useful for tracking business data, bill generation, and
                  digitizing a complete business.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Reports
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Business data is very important for businesses to understand
                  how, when, and where revenue generation is possible, the
                  provision of better service to customers, the utilization of
                  resources, and other data.
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} sx={{ padding: { sm: '20px 0px', md: '0px 20px' } }}>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Improves Cash Flow Management
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  With an app for monthly billing, businesses can easily track
                  and manage their cash flow. The app can provide real-time data
                  on outstanding invoices, payments received, and upcoming
                  payments, allowing businesses to make informed decisions about
                  their finances.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Reduces Errors
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Manual billing can result in errors such as incorrect
                  calculations or typos, which can lead to delayed payments or
                  disputes. An app for monthly billing can minimize errors by
                  automating the process and ensuring accuracy.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Accuracy
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Our business data and calculation are accurate. This accuracy
                  is of great importance as many factors like analytics,
                  forecasting, and taxes are dependent on the accuracy of
                  business data.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Customization
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  All businesses need customization as per their specific
                  requirements, every month. We are continuously upgrading our
                  application as per customer needs.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Improved inventory management
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  Inventory Management is allowing the business owner to take
                  different reports for better utilization of resources.
                </Typography>
              </Box>
              <Box py={1}>
                <Typography
                  fontFamily={"Be Vietnam Pro"}
                  fontSize={"20px"}
                  fontWeight={"600"}
                >
                  Payments
                </Typography>
                <Typography fontFamily={"Be Vietnam Pro"}>
                  A tally of online payments, and cash payments are very
                  important for internal and external audit purposes. All
                  businesses need help and transparency to get the correct data
                  sets which is possible through this application.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default LandingPage;
