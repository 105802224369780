import React, { useRef, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { useEffect } from "react";

import { getMonthlyDetails } from "./reportsApi";
import GstReportCard from "./gstReportCard";
import { getEndOfMonth, getFirstDateOfCurrentMonthPayloadFormate, getFirstDateOfMonth, getLastDateOfCurrentMonthPayloadFormat, getStartOfMonth, payloadDateFormat } from "../../../utils/commonUtils";

const GstReports = () => {
  const [open, setOpen] = useState(false);
  const datePickerRef2 = useRef();

  //------------date
  const [date, setDate] = useState(getStartOfMonth());
  const [displayEndDate, setDisplayEndDate] = useState(getEndOfMonth());

  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  const [endDate, setEndDate] = useState(getLastDateOfCurrentMonthPayloadFormat());

  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());


  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };


  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  const displayDateFormat = () => {
    var currentDate = new Date();
    let dateContainer = String(currentDate).split(" ");

    var year = dateContainer[2];
    var month = dateContainer[1];

    var day = dateContainer[3];

    var formattedDate =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

    return formattedDate;
  };
  const [gstReports, setgstReports] = useState({
    totalSales: "",
    gstReports: [],
  });
  const [billFilter, setBillFilter] = useState("cash");



  const getYear = (date) => {
    let ar = date.split('-');
    return ar[ar.length - 1]
  }
  // const [date, setDate] = useState(getYear(displayDateFormat()));

  const [startDate, setStartDate] = useState(new Date());
  const [search, setSearch] = useState("");
  const datePickerRef = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const getData = async (param) => {
    await getMonthlyDetails(param).then((res) => {
      console.log("Gst Report ", res?.data?.data);
      setgstReports({ ...gstReports, gstReports: res?.data?.data });
    });
  };
  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };


  useEffect(() => {
    console.log("datE ", date);
    getData({
      // year: Number(payloadDateFormat().split("-")[0]),
      fromDate: startDateFormat,
      toDate: endDate,
    });
  }, [startDateFormat, endDate]);
  useEffect(() => {
    console.log("datE ", date);
    getData({
      // year: Number(payloadDateFormat().split("-")[0]),
      fromDate: startDateFormat,
      toDate: endDate,
    });
  }, []);
  return (
    <>
      <Grid
        container
        sx={{
          px: "10px",
        }}
      >


        <Grid item xs={12}>
          <TextField
            fullWidth
            readonly
            onClick={openDatePicker}
            id="date"
            sx={{
              mt: 2,
              "& .MuiInputBase-root": { height: "45px" }
            }}
            value={date + " To " + displayEndDate}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <TextField
            fullWidth
            readonly
            onClick={openDatePicker}
            id="date"
            sx={{
              mt: 2,
              "& .MuiInputBase-root": { height: "45px" }
            }}
            value={date}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
          <DatePicker
            hidden={true}
            selected={startDate}
            maxDate={new Date()}
            onChange={(date) => {
              datePickerOnChange(date);
            }}
            className="custom-datepicker"
            ref={datePickerRef}
            onClickOutside={() => datePickerRef.current.setOpen(false)}
            showMonthYearPicker
          />
        </Grid> */}
        <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={datePickerOnChange}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          maxDate={new Date()}
          onChange={datePickerOnChangeEnd}
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2.current.setOpen(false);
          }}
        />

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          <Grid item xs={12} lg={12}>
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  Month
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Amount
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          >
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  Month
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Amount
              </Grid>
            </Grid>
          </Grid> */}
          <Grid container sx={{ maxHeight: "67vh", overflow: "auto" }}>

            {gstReports.gstReports.map((item, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  // lg={6}
                  key={"payamentRecpt" + i}
                  sx={{ p: "0px" }}
                >
                  <GstReportCard item={item} />
                </Grid>
              );
            })}
          </Grid>

        </Grid>
      </Grid>
      {/* </Box> */}
      {/* </Drawer> */}
    </>
  );
};

export default GstReports;
