export const displayOutstading=(outStandingBills,search)=>{

    let sum=0;
 //item?.balanceAmount
//   outStandingBills.forEach((item,index)=>{
//     sum+=item?.balanceAmount;
//   });

outStandingBills.records.forEach((item,index)=>{
    if (
        !item?.buyerName?.toLowerCase().includes(search.toLowerCase()) &&
        !item?.buyerMobile
          ?.toLowerCase()
          .includes(search.toLowerCase()) &&
        search.length > 0
      ) {
         
      }
      else{
        sum+=item?.balanceAmount;
      }
})
return sum

}