import { swalAlertPopProps } from "../../utils/commonUtils";

export const getUpdateData = async (param,updatePin,Swal) => {
    await updatePin(param)
      .then((res) => {
        console.log("mpin changes success fully, ", res);
        if(res?.data?.success){
          Swal.fire({
            title: res?.data?.message, 
            icon: "success",
  
            ...swalAlertPopProps,
          });
        }
        else{
          Swal.fire({
            title: res?.data?.message, 
            icon: "warning",
  
            ...swalAlertPopProps,
          });
        }
      
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };