import * as React from "react";
import Box from "@mui/material/Box";
import ListIcon from "@mui/icons-material/List";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import GridViewIcon from "@mui/icons-material/GridView";
import { routePath } from "../constants/routePath";
import { useNavigate } from "react-router";
export default function Footer() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        flexGrow: 1,
        position: "fixed",
        bottom: "0px",

        background: (theme) => theme.palette.primary.main,
        width: "100%",
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{ p: "10px 0px", borderRight: "0.2px solid #e5e5e5" }}
      >
        {/* <Grid
          item
          xs={12}
          sx={{
            borderRight: "0.2px solid #e5e5e5",
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        > */}
        <Grid container>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              height: "40px",
              // justifyContent: "space-around",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(routePath.MOBILEPAGEMENU);
            }}
          >
            {" "}
            <ListIcon sx={{ m: "0px 10px", color: "white" }} />
            <Typography
              sx={{ color: "white", fontWeight: "600", m: "0px" }}
              gutterBottom
              align="center"
            >
              Menu
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            onClick={() => {
              navigate(routePath.DASHBOARD);
            }}
            sx={{
              display: "flex",
              height: "40px",
              // justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <GridViewIcon sx={{ m: "0px 10px", color: "white" }} />
            <Typography
              sx={{ color: "white", fontWeight: "600", m: "0px" }}
              gutterBottom
              align="center"
            >
              Dashboard
            </Typography>
          </Grid>
        </Grid>
        <Box></Box>
        <Box></Box>
        {/* <Typography sx={{ color: "#505050" }} gutterBottom align="center">
            Copyright © 2023 MITR Learning & Media. All rights reserved.
          </Typography> */}
      </Grid>
      {/* </Grid> */}
    </Box>
  );
}
