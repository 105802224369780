import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import StockCard from "./stockCard";
import { getStockDetail } from "./stockApi";
import { useEffect } from "react";
import { useState } from "react";
import { getData } from "./stockCustomApiHandler";

const Stocks = () => {
  const [stocks, setStocks] = useState();
  const stockPayload = {
    page: 1,
    pageSize: 36,
  };

  const [pagination, setPagination] = useState({
    page: 1,
    total: 1,
    records: 0,
  });

  const handleChange = (event, value) => {
    getData({ ...stockPayload, page: value });
    setPagination({
      ...pagination,
      page: value,
    });
  };

  useEffect(() => {
    getData(stockPayload, getStockDetail, setStocks, setPagination, pagination);
  }, []);
  return (
    <Grid
      container
      sx={{
        p: "20px",
        display: "flex",
      }}
      spacing={2}
    >
      <Grid item md={5.5} container spacing={2}
        sx={{ display: "flex", flexDirection: "column", height: "80vh" }}

      >
        {stocks?.map((item, index) => {
          return (
            <Box
            sx={{
              m: 1,
              width: "50%",
              boxShadow: item.totalItemQuantity < 10 ? "0 0 10px 2px red" : "none",
              backgroundColor: item.totalItemQuantity < 10 ? "red" : "initial",
              color: item.totalItemQuantity < 10 ? "white" : "initial",
              animation: item.totalItemQuantity < 10 ? "blinking 1.5s infinite" : "none",
              "@keyframes blinking": {
                "0%": { boxShadow: "0 0 10px 2px red", backgroundColor: "red", color: "white" },
                "50%": { boxShadow: "0 0 10px 2px rgba(255, 0, 0, 0)", backgroundColor: "transparent", color: "red" },
                "100%": { boxShadow: "0 0 10px 2px red", backgroundColor: "red", color: "white" }
              }
            }}
          >
            <StockCard item={item} />
          </Box>
          
          
          
          );
        })}
        {/* {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Box sx={{ m: 1, width: "50%" }}>
              <StockCard item={item} />
            </Box>
          );
        })}
 */}

        {/* {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })} */}
        {/* {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })}
        {stocks?.map((item, index) => {
          return (
            <Grid item xs={12} md={4} lg={3} sx={{ mt: "10px" }}>
              <StockCard item={item} />
            </Grid>
          );
        })} */}
      </Grid>

      <Grid container sx={{ pt: "10px" }}>
        <Grid item xs={4}>
          <Typography
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <strong>Total Items: &nbsp;</strong>
            <strong> {pagination.records}&nbsp;</strong>
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            // bgcolor: "red",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Stack direction="row" justifyContent="space-around" sx={{ mt: 2 }}> */}
          <Pagination
            count={pagination.total}
            page={pagination.page}
            onChange={handleChange}
          />
          {/* </Stack> */}
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

    </Grid>
  );
};

export default Stocks;
