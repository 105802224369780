export const checkValidationHelpSupportModal = (modalData) => {
  if (modalData?.purpose === "") {
    return { status: false, message: "Please Select Purpose" };
  } else if (modalData?.description === "") {
    return {
      status: false,
      message: "Please Enter Sescription into 500 character! ",
    };
  }
  return {
    status: true,
    message: "",
  };
};
