import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
const data = {
  id: 0,
  name: "",
  isActive: true,
  items: [],
};

export default function AddTableSection({
  setSelectedSection,
  updateTableSectionData,
  section,
  selectedTables,
  setSelectedTables,
  allTables,
  open,
  addTable,
  setOpen,
}) {
  const onClose = () => {
    setItemData(data);
    setOpen(false);
  };
  const [itemData, setItemData] = useState(data);

  useEffect(() => {
    if (allTables) {
      const aa = allTables?.filter((i) => {
        return i.sectionId;
      });
      if (aa.length > 0) setSelectedTables([...aa]);
    }
  }, [allTables]);

  useEffect(() => {
    if (section) {
      setItemData({ ...itemData, ...section });
    }
  }, [section]);

  const onChange = (key, value) => {
    setItemData({ ...itemData, [key]: value });
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={onClose}>
      <DialogTitle
        color="primary"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Table Section{" "}
        <CloseIcon
          sx={{ float: "right", cursor: "pointer" }}
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ p: 1 }}>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            value={itemData.name}
            onChange={(e) => {
              setItemData({ ...itemData, name: e.target.value });
            }}
            label={
              <span>
                Table Section<span style={{ color: "red" }}>*</span>
              </span>
            }
          />

          {section && (
            <FormGroup>
              <FormControlLabel
                sx={{
                  justifyContent: "space-between",
                  m: 0,
                  flexDirection: "row-reverse",
                }}
                control={
                  <Switch
                    checked={itemData.isActive}
                    onChange={(e) => {
                      onChange("isActive", e.target.checked);
                    }}
                  />
                }
                label="Status"
              />
            </FormGroup>
          )}

          <FormControl sx={{ width: "100%" }} component="fieldset">
            {allTables?.map((item2, index) => {
              return (
                <FormGroup
                  sx={{ width: "100%" }}
                  aria-label="position"
                  row
                  key={"notAssigntable" + index}
                >
                  <FormControlLabel
                    sx={{
                      width: "100%",
                      justifyContent: "space-between",
                      px: 2,
                      m: 0,
                    }}
                    control={
                      <Checkbox
                        checked={
                          selectedTables.findIndex(
                            (item) => item.id === allTables[index].id
                          ) !== -1
                        }
                        onChange={(e) => {
                          if (
                            selectedTables.findIndex(
                              (item) => item.id === allTables[index].id
                            ) !== -1
                          ) {
                            const tempArry = [...selectedTables];
                            const ind = tempArry.findIndex(
                              (i) => i.id === item2.id
                            );

                            if (ind != -1) tempArry.splice(ind, 1);

                            setSelectedTables([...tempArry]);
                          } else {
                            const tempArry = [
                              ...selectedTables,
                              { ...item2, isActive: true },
                            ];
                            setSelectedTables([...tempArry]);
                          }
                        }}
                      />
                    }
                    label={item2?.name}
                    // labelPlacement="left"

                    labelPlacement="start"
                  />
                </FormGroup>
              );
            })}
          </FormControl>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          disabled={
            itemData?.name?.trim().length === 0 || selectedTables.length === 0
          }
          variant="contained"
          onClick={() => {
            if (section) {
              updateTableSectionData({ ...itemData, items: selectedTables });
            } else addTable({ ...itemData, items: selectedTables });

            setOpen(false);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
