import React, { useRef, useState } from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import EventIcon from "@mui/icons-material/Event";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DatePicker from "react-datepicker";

import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "react-datepicker/dist/react-datepicker.css";

// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useEffect } from "react";

import SearchIcon from "@mui/icons-material/Search";
import { getCounterSalesSummary } from "./reportsApi";
// import GstReportCard from "./gstReportCard";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import CounterSalesItemCard from "./counterItemCard";
import axios from "../../../utils/axios";
import { downloadXLSXFile, getFirstDateOfCurrentMonthPayloadFormate, getFirstDateOfMonth, getStartOfMonth, payloadDateFormat } from "../../../utils/commonUtils";
import { useSelector } from "react-redux";
import { api } from "../../../utils/api";
const CashCounterSale = () => {
  function addLeadingZero(value) {
    return value < 10 ? "0" + value : value;
  }

  const displayDateFormat = () => {
    var currentDate = new Date();
    let dateContainer = String(currentDate).split(" ");

    var year = dateContainer[2];
    var month = dateContainer[1];

    var day = dateContainer[3];

    var formattedDate =
      year + "-" + addLeadingZero(month) + "-" + addLeadingZero(day);

    return formattedDate;
  };
  const [date, setDate] = useState(getStartOfMonth());
  const [startDateFormat, setstartDateFormat] = useState(getFirstDateOfCurrentMonthPayloadFormate());
  const [SelectedStartDate, setSelectedStartDate] = useState(getFirstDateOfMonth());
  // const [date, setDate] = useState(displayDateFormat);
  const [displayEndDate, setDisplayEndDate] = useState(displayDateFormat);

  // const [SelectedStartDate, setSelectedStartDate] = useState(new Date());
  // const [startDateFormat, setstartDateFormat] = useState(payloadDateFormat());

  const [endDate, setEndDate] = useState(payloadDateFormat());
  const [cashCounterSummary, setCashCounterSummary] = useState();
  const datePickerRef = useRef();
  const datePickerRef2 = useRef();

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  const getSaleSummary = async (param) => {
    await getCounterSalesSummary(param)
      .then((res) => {
        console.log("salary sumarry", res);
        setCashCounterSummary(res?.data?.data);
        // setCashCounterSummary()
      })
      .catch((err) => {
        console.log("err ", err);
      });
  };
  const datePickerOnChange = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    datePickerRef2.current.setOpen(true);

    setDate(formattedDate2);
    setstartDateFormat(formattedDate);
    setSelectedStartDate(date);
  };
  const datePickerOnChangeEnd = (date) => {
    const dateStr = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const dateStr2 = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    });
    const [month, day, year] = dateStr.split("/");
    const formattedDate = `${year}-${day.padStart(2, "0")}-${month.padStart(
      2,
      "0"
    )}`;
    let dateContainer = String(dateStr2).split(" ");
    var year2 = dateContainer[2];
    var month2 = dateContainer[1];
    var day2 = dateContainer[0];
    var formattedDate2 = day2 + "-" + month2 + "-" + year2;
    console.log("format date ", formattedDate);
    datePickerRef2.current.setOpen(true);
    setSelectedEndDate(date);
    setDisplayEndDate(formattedDate2);
    setEndDate(formattedDate);
  };
  const cashCounterSaleSummaryPayload = useSelector(
    (state) => state.reducer.cashCounterSaleSummaryPayload
  );

  useEffect(() => {
    console.log("start date ", startDateFormat);
    console.log("display End ", endDate);

    let payload = { ...cashCounterSaleSummaryPayload };
    delete payload.itemName;
    getSaleSummary({
      ...cashCounterSaleSummaryPayload,
      fromDate: startDateFormat,
      toDate: endDate,
    });
  }, [startDateFormat, endDate]);
  useEffect(() => {
    console.log("datE ", payloadDateFormat());

    let payload = { ...cashCounterSaleSummaryPayload };
    delete payload.itemName;
    getSaleSummary({
      ...cashCounterSaleSummaryPayload,
      fromDate: startDateFormat,
      toDate: endDate,
    });
  }, [cashCounterSaleSummaryPayload?.itemName]);
  const [SelectedEndDate, setSelectedEndDate] = useState(null);

  return (
    <>
      <Grid
        container
        sx={{
          p: "20px",
          display: "flex",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", height: "56px" }}
        >
          <TextField
            fullWidth
            size="small"
            disabled={true}
            id="name"
            value={cashCounterSaleSummaryPayload?.itemName}
            onChange={(e) => { }}
            // sx={{ "& .MuiInputBase-root": { height: "45px" } }}
            InputProps={{
              endAdornment: (
                <SearchIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                  }}
                  onClick={() => {
                    console.log("clicked");
                  }}
                />
              ),
            }}
          />

        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", height: "56px" }}
        >

          <TextField
            fullWidth
            readonly
            size="small"
            onClick={openDatePicker}
            id="date"
            sx={{
              // mt: 2,
              // "& .MuiInputBase-root": { height: "45px" },
            }}
            value={date + " To " + displayEndDate}
            label={
              <Box
                sx={{
                  flex: 4,
                  color: "grey",
                  cursor: "pointer",
                  whiteSpace: "nowrap",
                }}
              >
                Date :
              </Box>
            }
            onChange={(e) => { }}
            InputProps={{
              endAdornment: (
                <EventIcon
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                />
              ),
            }}
          />
          <FileDownloadIcon
            sx={{ ml: "10px", mr: "0px", cursor: "pointer" }}
            onClick={() => {

              downloadXLSXFile(
                api + "CounterSales/GetCounterSalesSummary" + "Xlsx",
                {
                  ...cashCounterSaleSummaryPayload,
                  fromDate: startDateFormat,
                  toDate: endDate,
                }
              );
            }}
          />
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          ></Box>
        </Grid>

        <DatePicker
          hidden={true}
          selected={SelectedStartDate}
          maxDate={new Date()}
          onChange={datePickerOnChange}
          className="custom-datepicker"
          ref={datePickerRef}
          onClickOutside={() => {
            datePickerRef.current.setOpen(false);
          }}
        />

        <DatePicker
          hidden={true}
          selected={SelectedEndDate}
          maxDate={new Date()}
          onChange={datePickerOnChangeEnd}
          className="custom-datepicker"
          ref={datePickerRef2}
          onClickOutside={() => {
            datePickerRef2.current.setOpen(false);
          }}
        />

        <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Total Quantity:
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {" "}
            {cashCounterSummary?.totalQuantity}
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Daily Average:
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
            {cashCounterSummary?.dailyAverage?.toFixed(2)}
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Total Amt:
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
            {cashCounterSummary?.totalAmount?.toFixed(2)}
          </Box>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            mt: "10px",
            padding: "8px 16px",
            fontWeight: "500",
          }}
        >
          <Box
            component="span"
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            Daily Amt:
          </Box>
          <Box
            component="span"
            sx={{
              marginLeft: "8px",
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            <CurrencyRupeeIcon sx={{ fontSize: "14px" }} />{" "}
            {cashCounterSummary?.dailyAmount?.toFixed(2)}
          </Box>
        </Grid>

        <Divider
          sx={{
            width: "100%",
            mt: 2,
            background: (theme) => theme.palette.primary.main,
          }}
        />

        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                  }}
                >
                  Date
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Qty
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Amount
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          >
            <Grid container sx={{ p: "10px" }}>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    fontSize: "15px",
                    overflow: "Hidden",
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    fontWeight: 700,
                  }}
                >
                  {" "}
                  Date
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Qty
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  fontSize: "15px",
                  overflow: "Hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Amount
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ maxHeight: "45vh", overflow: "auto" }}>
            {cashCounterSummary?.counterSalesDetails?.map((item, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  key={"payamentRecpt" + i}
                  sx={{ p: "2px", borderRight: "3px solid #dfdfdf" }}
                >
                  <CounterSalesItemCard item={item} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CashCounterSale;
