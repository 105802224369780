import {
  Box,
  Fab,
  Grid,
  InputAdornment,
  Pagination,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  addCategories,
  getItemCategories,
  getItems,
  updateCategories,
} from "./itemCategoriesApi";
import AddCategories from "./addCategories";
import CategoriesCard from "./CategoriesCard";
import Swal from "sweetalert2";
import {
  addCategoriesData,
  getCategories,
  getItemsNames,
  updateCategoriesData,
} from "./itemCategoriesCustomApiHandler";
export default function ItemCategories() {
  const [pagination, setPagination] = useState({ page: 1, total: 1 });
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [itemCategories, setItemCategories] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);
  const getCategoriesKeys = {
    page: 0,
    pageSize: 16,
    filterByColumn: "",
    filterValue: "",
    searchByColumn: "",
    searchValue: "",
    orderByColumn: "",
    orderBy: "",
  };

  useEffect(() => {
    getCategories(
      getCategoriesKeys,
      getItemCategories,
      setItemCategories,
      setPagination,
      setTotalItems
    );
  }, []);

  useEffect(() => {}, []);

  const handleChange = (event, value) => {
    getCategories(
      { ...getCategoriesKeys, page: value },
      getItemCategories,
      setItemCategories,
      setPagination,
      setTotalItems
    );
    setPagination({
      ...pagination,
      page: value,
    });
  };
  useEffect(() => {
    if (open === false) {
      setSelectedCategories(null);
    }
  }, [open]);
  return (
    <Box sx={{ p: 2, height: "calc(100vh - 70px)", overflowY: "auto" }}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="end"
        sx={{
          position: {
            xs: "fixed",
            sm: "relative",
          },
          width: {
            xs: "40%",
            sm: "100%",
          },
          top: {
            xs: "61px",
            sm: "0px",
          },
          right: {
            xs: "10px",
            sm: "0px",
          },
        }}
      >
        <Fab
          sx={{ padding: "1rem" }}
          onClick={() => {
            getItemsNames({ categoryId: 0 }, getItems, setAllItems);
            setOpen(true);
          }}
          // sx={{ textTransform: "capitalize" }}
          size="small"
          color="primary"
          variant="extended"
          aria-label="add"
        >
          Add Item
          <AddIcon />
        </Fab>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {itemCategories?.map((item) => {
          return (
            <Grid item lg={2.3} sm={4} xs={12} md={3}>
              <CategoriesCard
                setEditedItem={() => {
                  setSelectedCategories(item);
                  getItemsNames(
                    { categoryId: item?.id },
                    getItems,
                    setAllItems
                  );

                  setOpen(true);
                }}
                item={item}
              />
            </Grid>
          );
        })}
      </Grid>
      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
        <Typography color="primary">
          <strong>Total Items: &nbsp;</strong>
          <strong> {totalItems}</strong>
        </Typography>
        <Pagination
          count={pagination.total}
          page={pagination.page}
          onChange={handleChange}
        />
      </Stack>
      <AddCategories
        open={open}
        setOpen={setOpen}
        allItems={allItems}
        addCategoriesData={(data) =>
          addCategoriesData(
            data,
            addCategories,
            Swal,
            setSelectedItems,
            getCategoriesKeys,
            getItemCategories,
            setItemCategories,
            setPagination,
            setTotalItems
          )
        }
        section={selectedCategories}
        updateCategoriesData={(data) =>
          updateCategoriesData(
            data,
            updateCategories,
            Swal,
            getCategoriesKeys,
            getCategories,
            getItemCategories,
            setItemCategories,
            setPagination,
            setTotalItems
          )
        }
        setAllItems={setAllItems}
        setSelectedCategories={setSelectedCategories}
        selectedCategories={selectedCategories}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </Box>
  );
}
