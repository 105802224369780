export const routePath = {
  ROOT: "/*",
  LANDINGPAGE: "/home",
  LANDINGPAGEHOME: "/homepage",
  LANDINGPAGERESTORENT: "/restorant",
  LANDINGPAGECOUNTERTOKEN: "/token",
  LANDINGPAGEMONTHLYBILL: "/monthlybill",
  LANDINGPAGEFRANCHISE: "/franchise",
  LOGIN: "/login",
  DASHBOARD: "/",
  COUNTERSELL: "/countersell",
  COUNTERSELLBILLING: "/countersellbilling",
  MYPROFILE: "/myprofile",
  MYBUSSINESS: "/mybusiness",
  TABLEMASTER: "/tablemaster",
  UPDATEBILLS: "/updatebills",
  ITEMSERVICES: "/item-services",
  TABLESECTION: "/table-section",
  ITEMCATEGORIES: "/item-categories",
  ASSOCIATEDUSERS: "/associated-users",
  PAYMENTRECEIPT: "/payament-receipt",
  OUTSTANDINGREPORT: "/outstanding-report",
  MOBILEPAGEMENU: "/mobil-emenu-Page",
  SMBACCOUNTREACHARGE: "/smb-account-reacharge",
  ITEMWISESALE: "/item-wise-sale",
  REPORTS: "/reports",
  STOCKS: "/stocks",
  PURCHASE: "/purchase",
  EXPENCE: "/expence",
  HELPSUPPORT: "/help-support",
  RESETMPIN: "/reset-mpin",
  SETTING: "/setting",
  RATECHANGE: "/rate-change",
  CASHCOUNTERSALE: "/cash-counter-sale",
  KYCPROCESS: "/kyc-process"
};
