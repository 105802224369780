import { ADDTABLE, GETMASTERTABLE, UPDATETABLEMASTER } from "../../utils/api";
import axios from "../../utils/axios";

export const getMasterdApi = async () => {
    const res = await axios.get(GETMASTERTABLE);
    return res;
  };

  export const updateTableData = async (params) => {
    const res = await axios.post(UPDATETABLEMASTER, params);
    return res;
  };
  
export const addTable = async (param) => {
    const res = await axios.post(ADDTABLE, param);
    return res;
  };
  