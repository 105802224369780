export const loginValidation = (loginData) => {
  if (loginData.username.length !== 10) {
    return {
      status: false,
      title: "Please Enter Valid Phone No",
      text: "Enter 10 digit number",
    };
  } else if (loginData.mpin.trim().length !== 4) {
    return {
      status: false,
      title: "Please Enter Valid Mpin",
      text: "Enter 10 digit number",
    };
  }
  return { status: true };
};
