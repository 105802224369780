import { UPDATEUSERPROFILE, UPDATEUSERPROFILIMAGE, USERPROFILE, USERPROFILEIMG } from "../../utils/api";
import axios from "../../utils/axios";

export const GetUserProfileApi = async (params) => {
    const res = await axios.get(USERPROFILE, params);
    return res;
  };
  export const UpdateUserProfileApi = async (params) => {
    const res = await axios.post(UPDATEUSERPROFILE, params);
    return res;
  };  
  export const UpdateUserProfileImageApi = async (params) => {
    const res = await axios.post(UPDATEUSERPROFILIMAGE, params);
    return res;
  };
  
  export const getUserProfileImg = async (param) => {
    const res = await axios.get(USERPROFILEIMG);
    return res;
  };
  
  