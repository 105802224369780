import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import { useNavigate } from "react-router";
import "./login.css";

import qs from "qs";
import store from "../../app/store/store";

import { stopLoading } from "../../app/store/reducer";
import { encrypt } from "../../utils/encryption";
import Swal from "sweetalert2";
import { LoginApi } from "./loginApi";

import logo from "../../assets/headers/logoSMB.png";
import { swalAlertPopProps } from "../../utils/commonUtils";
import { ENCRYPTIONKEY } from "../../utils/api";
import { loginValidation } from "./loginValidationFunction";
export default function Login() {
  const navigate = useNavigate();
  function generateRandomString(length) {
    const chars = "0123456789"; // Change this to include other characters if needed
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }

  const [loginData, setLoginData] = useState({
    username: "",
    grant_type: "implicit",
    client_id: "client",
    ismpinauth: "1",
    mpin: "",
    // mpin: "",
    password: '""',
    loggedinweb: "0",
  });

  const handleSubmit = async () => {
    const obj = { ...loginData };
    const val = loginValidation(loginData);
    if (val?.status) {
      console.log("mpin login ", loginData.mpin);
      const randomString = generateRandomString(4);
      obj.username = encrypt(loginData.username + randomString, ENCRYPTIONKEY);
      obj.mpin = encrypt(loginData.mpin + randomString, ENCRYPTIONKEY);

      let loginTmp = qs.stringify(obj);

      await LoginApi(loginTmp)
        .then(async (res) => {
          await sessionStorage.setItem("smbToken", res.data.access_token);
          navigate("/");
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Swal.fire({
              title: "Invalid Username or Password",
              text: "Please enter a valid username and password",
              icon: "error",

              ...swalAlertPopProps,
            });
            store.dispatch(stopLoading());
          }
        });
    } else {
      Swal.fire({
        title: val.title,
        text: val.text,
        icon: "warning",

        ...swalAlertPopProps,
      });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };
  return (
    <Grid
      container
      sx={{
        height: "auto",

        minHeight: "100vh",
        width: "100vw",

        backgroundColor: "#f6f9ff",
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={logo} style={{ width: "80%" }} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: {
            xs: "center",
            sm: "right",
          },
          p: "10px",
          paddingRight: {
            xs: "10px",
            sm: "50px",
          },
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.2)",
            padding: " 40px 30px ",
            background: "  white ",
            borderRadius: " 5px",
            float: "right",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "600",
              color: "#012970",
            }}
          >
            Login to Your Account
          </Typography>
          <Typography variant="subtitle2" sx={{ margin: "10px 0px" }}>
            Enter register mobile number & MPIN to login
          </Typography>
          <Typography variant="subtitle2" sx={{ margin: "10px 0px" }}>
            Mobile Number
          </Typography>
          <TextField
            fullWidth
            value={loginData.username}
            sx={{
              width: "100%",
              p: 0,
              color: "#212529",
              backgroundSize: "16px 12px",
              borderRadius: "0.375rem",
            }}
            InputProps={{
              style: {
                borderWidth: "0px",
              },
            }}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              let val = e.target.value;
              val = val.replace(/\D/g, "");
              if (val.length <= 10) {
                setLoginData({ ...loginData, username: val });
              }
            }}
          />
          <Typography variant="subtitle2" sx={{ margin: "10px 0px" }}>
            MPIN
          </Typography>

          <TextField
            fullWidth
            type="password"
            sx={{
              width: "100%",
              p: 0,
              color: "#212529",
              backgroundSize: "16px 12px",
              borderRadius: "0.375rem",
            }}
            value={loginData.mpin}
            onChange={(e) => {
              let val = e.target.value;
              val = val.replace(/\D/g, "");
              if (val.length <= 4) {
                setLoginData({ ...loginData, mpin: val });
              }
            }}
            onKeyDown={handleKeyPress}
            InputProps={{
              style: {
                borderWidth: "0px",
              },
            }}
          />

          <button
            className="buttonlogin"
            style={{ cursor: "pointer" }}
            onClick={handleSubmit}
          >
            Login
          </button>
        </Box>
      </Grid>
      {/* <Grid item lg={3} md={12} sm={12} xs={12}></Grid> */}
    </Grid>
  );
}
