import { LOGIN } from "../../utils/api";
import axios from "../../utils/axios";

export const LoginApi = async (params) => {
    console.log("login api");
    const res = await axios.post(LOGIN, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return res;
  };
  