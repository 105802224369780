import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router";

import React, { useEffect } from "react";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { routePath } from "./constants/routePath";

import { Drawer, Fab, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Loader from "./common/loader";
import SideBar from "./common/sideBax";

import Header from "./common/header";
import {
  GetUserApi,
  GetUserMenuApi,
  GetUserMenuHeaderApi,
} from "./utils/utils";

import {
  setHeaderMenu,
  setSideBarMenu,
  setTableName,
  setUserDetail,
} from "./app/store/reducer";

import SimpleSnackbar from "./common/Snackbar";

import MobileHeader from "./common/mobileHeader";
import { useState } from "react";
import Footer from "./common/Footer";

import {
  ensureAuthenticatedUserOrRedirectToLandingPage,
  footerRenderingAccordingToPages,
  halfSideBarRenderingAccordingToPages,
  handleHeaderRenderingAccordingTopages,
  handleMobileHeaderRederingAccordingToPages,
  pageGridSizeAccordingToLocation,
} from "./utils/routeUtils";
import HalfSideBar from "./common/halfSideBar";
import { counterSellSetOnLastIndex } from "./utils/commonUtils";
import { RoutesSet } from "./utils/routeSet";
import { GetBussiness } from "./features/myBusiness/myBusinessApi";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const menuLoginHeader = [
    {
      title: "Home",
    },
    {
      title: "About",
    },
  ];

  const getToken = async () => {
    const token = await sessionStorage.getItem("smbToken");
    if (token) {
      navigate("/");
    }
  };
  const [drawerShow, setDrawerShow] = useState(false);
  const userDetail = useSelector((state) => state.reducer.userDetail);
  useEffect(() => {
    console.log("userDetail ", userDetail);
  }, [userDetail]);
  const tableName = useSelector((state) => state.reducer.tableName);
  const dispatch = useDispatch();

  const getUser = async () => {
    await GetUserApi()
      .then(async (res) => {
        let tmpChangeBus = { ...res.data.data };
        // businessLineCode
        // dispatch(setUserDetail({ ...tmpChangeBus, businessLineCode: "CB" }));
        dispatch(setUserDetail({ ...tmpChangeBus }));

        await GetUserMenuApi(
          res.data.data.businessLineId,
          res.data.data.languageCode
        )
          .then((res) => {
            dispatch(setSideBarMenu(res.data.data.hamburger));
          })
          .catch((err) => {
            console.error("res ", err);
          });

        await GetUserMenuHeaderApi(
          res.data.data.businessLineId,
          res.data.data.languageCode
        )
          .then((res) => {
            console.log("res.data.data.mainMenu ", res.data.data.mainMenu);
            let data = res.data.data.mainMenu;
            // console.log("index header ss", counterSellSetOnLastIndex(data));
            dispatch(setHeaderMenu(counterSellSetOnLastIndex(data)));
          })
          .catch((err) => {
            console.error("res ", err);
          });
      })
      .catch((err) => {
        console.error("res ", err);
      });
  };
  function isObjectEmpty(obj) {
    return Object.entries(obj).length === 0;
  }
  useEffect(() => {
    const token = sessionStorage.getItem("smbToken");
    if (
      ensureAuthenticatedUserOrRedirectToLandingPage(token, location, routePath)
    ) {
      navigate(routePath.LANDINGPAGE);
      return;
    }
  }, [])
  useEffect(() => {
    const token = sessionStorage.getItem("smbToken");
    if (
      ensureAuthenticatedUserOrRedirectToLandingPage(token, location, routePath)
    ) {
      navigate(routePath.LANDINGPAGE);
      return;
    }
    if (token && location.pathname === routePath.LANDINGPAGE) {
      navigate(routePath.DASHBOARD);
      return;
    }

    if (
      tableName === null &&
      location.pathname === routePath.COUNTERSELLBILLING
    ) {
      navigate(routePath.COUNTERSELL);
      // return;
    }
    if (
      location.pathname === routePath.COUNTERSELL &&
      // userDetail?.businessLineCode !== "RE" &&
      userDetail?.businessLineCode
    ) {
      if (userDetail?.businessLineCode === "RE") {
        navigate(routePath.COUNTERSELL);
      } else {
        dispatch(setTableName(userDetail?.name));
        navigate(routePath.COUNTERSELLBILLING);
      }
    }
    if (token !== null && isObjectEmpty(userDetail)) {
      getUser();
    }

    if (location.pathname === "/login") {
      getToken();
    }
  }, [location.pathname, userDetail]);


  const [flag,setFlat]=useState({
    getData:false
  })
  const getData = async () => {
    await GetBussiness().then((res) => {
      console.log("es.data.data[0] ", res.data.data[0])
  dispatch(setUserDetail({...userDetail,uploadedLogo:res.data.data[0]?.logoPath}))
      // setMyBussinessData({
      //   ...res.data.data[0],
      //   mobileNumber: userDetail?.mobileNumber,
      // });
    });
  };
useEffect(() => {
    console.log("userDetail ", userDetail);
    if(Object.keys(userDetail).length>0){
      if(!flag.getData){
        getData()
        setFlat({...flag,getData:true})
      }
    }
  }, [userDetail]);
  return (

    <div>
      <Loader />
      <SimpleSnackbar />
      <Grid container spacing={0}>
        <Grid
          item
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: {
              xs: "none",
              sm: handleHeaderRenderingAccordingTopages(routePath, location)
                ? "none"
                : "block",
            },
          }}
        >
          <Header
            setDrawerShowSideBar={(data) => setDrawerShow(data)}
            menuLoginHeader={
              // location.pathname === "/login" ? menuLoginHeader : null
              location.pathname === routePath.LOGIN ? menuLoginHeader : null
            }
          />
        </Grid>
        {handleMobileHeaderRederingAccordingToPages(location, routePath) && (
          <React.Fragment>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              lg={2}
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                },
              }}
            >
              <MobileHeader setDrawerShow={setDrawerShow} />
            </Grid>
          </React.Fragment>
        )}
        <Grid
          item
          sm={0.5}
          sx={{
            display: {
              xs: "none",
              sm: halfSideBarRenderingAccordingToPages(location, routePath)
                ? "block"
                : "none",
            },
          }}
          display={
            halfSideBarRenderingAccordingToPages(location, routePath)
              ? "block"
              : "none"
          }
        >
          <HalfSideBar setDrawerShowSideBar={(data) => setDrawerShow(data)} />
        </Grid>

        <Grid
          item
          sx={{}}
          xs={12}
          sm={
            pageGridSizeAccordingToLocation(location, routePath)
              ? // false
              11.5
              : 12
          }
          md={pageGridSizeAccordingToLocation(location, routePath) ? 11.5 : 12}
          lg={pageGridSizeAccordingToLocation(location, routePath) ? 11.5 : 12}
        >
          <Routes>

            {RoutesSet.map((item, index) => {
              return <Route path={item.routePath} element={item.component} />;
            })}

          </Routes>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: footerRenderingAccordingToPages(location, routePath)
                ? "block"
                : "none",
              sm: "none",
            },
          }}
        >
          <Footer />
        </Grid>
      </Grid>
      <Drawer
        anchor={"left"}
        open={drawerShow}
        onClose={() => {
          setDrawerShow(false);
        }}
        onClick={() => setDrawerShow(false)}
      >
        <SideBar menuLoginHeader={menuLoginHeader} />

      </Drawer>
      {footerRenderingAccordingToPages(location, routePath) === false &&
        (
          <Fab
            // color="red"
            sx={{
              position: 'fixed',
              bottom: '30px',
              right: "40px",
              backgroundColor: "#25D366 !important",
              width: "70px",
              height: "70px",
            }}
            onClick={() => {

              const whatsappLink = `https://web.whatsapp.com/send?phone=${9209054709}&text=Hello`;
              window.open(whatsappLink, "_blank");
            }}
          >
            <WhatsAppIcon sx={{
              color: "white",
              fontSize: '40px'
            }}
            />
          </Fab>
        )
      }

    </div>
  );
}

export default App;
